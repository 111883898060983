import { hasPermissionByDescription, verifyPermissionDescription, searchPermissions } from '@/components/helpers';
import { PERMISSION_IDS } from '@/constants';
import AuthService from '@/services/auth.service';
import Vue from 'vue';

import WebStorage from '@/common/WebStorage';

export const projectPermission = Vue.extend({
	data () {
		return {
			PERMISSION_IDS,
			dataProject: '',
			dataIntegration: '',
		};
	},

	computed: {
		manageProjectPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageProjectId, this.dataIntegration?.childrens);
		},

		editProjectPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.editProjectId, this.dataProject?.childrens);
		},
		createProjectPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.createProjectId, this.dataProject?.childrens);
		},
		deleteProjectPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.deleteProjectId, this.dataProject?.childrens);
		},
		duplicateProjectPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.duplicateProjectId, this.dataProject?.childrens);
		},
		shareProjectPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.shareProjectId, this.dataProject?.childrens);
		},
		createFlowsPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.createFlowId, this.dataProject?.childrens);
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	async created() {
		await this.validationAccessProject();
	},

	methods: {
		async validationAccessProject() {
			const result = await searchPermissions();

			if(result === false) {
				await AuthService.logout();

				return this.$store.commit('activeAlert', {
					message: this.$locale.baseText('login.noPermission'),
					status: 'error',
				});
			} else {
				const permission = JSON.parse(WebStorage.getItem('userPermission'));
				this.dataIntegration = verifyPermissionDescription(this.PERMISSION_IDS?.manageIntegrationId, permission?.access);
				this.dataProject = verifyPermissionDescription(this.PERMISSION_IDS?.manageProjectId, this.dataIntegration?.childrens);

				if (!this.dataIntegration && !this.dataProject) {
					await AuthService.logout();

					return this.$store.commit('activeAlert', {
						message: this.$locale.baseText('login.noPermission'),
						status: 'error',
					});
				} else if (!this.dataProject && this.$route.name === 'projectDetailing') {
					this.$router.push({ name: 'project' });
				}
			}
		},

		canAcessProject() {
			return this.manageProjectPermission && this.workspace.ativo;
		},
		canRenameProject(project, projectStatusActive) {
			return this.editProjectPermission && projectStatusActive && project.permissao == 'Editar' && this.workspace.ativo ? true : false;
		},
		canToggleActiveProject(project) {
			return this.editProjectPermission && project.permissao == 'Editar' && this.workspace.ativo;
		},
		canCreateProject() {
			return this.createProjectPermission && this.workspace.ativo;
		},
		canDeleteProject(project, projectStatusActive) {
			return this.deleteProjectPermission && project.permissao == 'Editar' && this.workspace.ativo ? true : false;
		},
		canDuplicateProject(project, projectStatusActive) {
			return this.duplicateProjectPermission && projectStatusActive && this.workspace.ativo ? true : false;
		},
		canShareProject(project, projectStatusActive) {
			return this.shareProjectPermission && projectStatusActive && project.permissao == 'Editar' && this.workspace.ativo ? true : false;
		},
		canViewShareProject(project, projectStatusActive) {
			return this.shareProjectPermission && projectStatusActive && this.workspace.ativo ? true : false;
		},
		canShareBatchProject() {
			return this.shareProjectPermission && this.workspace.ativo;
		},
		canCreateFlow(project, projectStatusActive) {
			return this.createFlowsPermission && projectStatusActive && project.permissao == 'Editar' && this.workspace.ativo ? true : false;
		},
	},
});
