import { hasPermissionByDescription, verifyPermissionDescription, searchPermissions, verifyAdminUser } from '@/components/helpers';
import { PERMISSION_IDS } from '@/constants';
import AuthService from '@/services/auth.service';
import Vue from 'vue';

import WebStorage from '@/common/WebStorage';

export const mainSiderBarPermission = Vue.extend({
	data() {
		return {
			PERMISSION_IDS,
			dataLogs: '',
			dataTeams: '',
			dataPanelIntegration: '',
			dataIntegration: '',
			dataStore: '',
			dataWorkspace: '',
			dataExecution: '',
			permission: '',
			isAdmin: '',
		};
	},

	async created() {
		await this.validationAccessMainSidebar();
	},

	computed: {
		manageIntegrations() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageIntegrationsStore, this.permission?.access);
		},
		accessStore() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.accessStore, this.permission?.access);
		},
		showIntegrationPermission() {
			return this.dataIntegration === undefined ? false : true;
		},
		manageRequestIntegrations() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageRequestIntegrations, this.permission?.access);
		},
		showProjectPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageProjectId, this.dataIntegration?.childrens);
		},
		showCredentialsPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageCredentialsId, this.dataIntegration?.childrens);
		},
		showVariablePermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageVariableId, this.dataIntegration?.childrens);
		},
		showEquivalencesPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageEquivalencesId, this.dataIntegration?.childrens);
		},
		showAdminPanelIntegrationPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.adminIntegrationPanel, this.permission?.access);
		},
		accessPanelIntegrationPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.accessIntegrationPanel, this.permission?.access);
		},
		showLogsPermission() {
			return this.dataLogs === undefined ? false : true;
		},
		showExecutionLogPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.acessExecutionsId, this.dataLogs?.childrens);
		},
		showTeamsPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageUsersId, this.dataTeams?.childrens);
		},
		showUsersPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageUsersId, this.dataTeams?.childrens);
		},
		showUserProfilePermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageUserProfileId, this.dataTeams?.childrens);
		},
		showWorkspace() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageWorkspaceId, this.permission?.access);
		},
		showIntegrationPanel() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageWorkspaceId, this.permission?.access);
		},
		showExecutionQueue() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageExecutionQueue, this.permission?.access);
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},

	methods: {
		async validationAccessMainSidebar() {
			const result = await searchPermissions();
			this.isAdmin = await verifyAdminUser();

			if (result === false) {
				await AuthService.logout();

				return this.$store.commit('activeAlert', {
					message: this.$locale.baseText('login.noPermission'),
					status: 'error',
				});
			} else {

				this.permission = JSON.parse(WebStorage.getItem('userPermission'));
				this.dataIntegration = verifyPermissionDescription(this.PERMISSION_IDS?.manageIntegrationId, this.permission?.access);
				this.dataLogs = verifyPermissionDescription(this.PERMISSION_IDS?.manageLogs, this.permission?.access);
				this.dataTeams = verifyPermissionDescription(this.PERMISSION_IDS?.manageTeamsId, this.permission?.access);
				this.dataWorkspace = verifyPermissionDescription(this.PERMISSION_IDS?.manageWorkspaceId, this.permission?.access);
				this.dataExecution = verifyPermissionDescription(this.PERMISSION_IDS?.manageExecutionQueue, this.permission?.access);
				this.dataStore = verifyPermissionDescription(this.PERMISSION_IDS?.manageIntegrationsStore, this.permission?.access);

				if (!this.dataIntegration && !this.dataLogs && !this.dataTeams) {
					await AuthService.logout();

					return this.$store.commit('activeAlert', {
						message: this.$locale.baseText('login.noPermission'),
						status: 'error',
					});
				}
			}
		},

		canViewIntegration() {
			return this.showIntegrationPermission && this.workspace.ativo;
		},
		canViewProject() {
			return this.showProjectPermission && this.workspace.ativo;
		},
		canViewCredentials() {
			return this.showCredentialsPermission && this.workspace.ativo;
		},
		canViewVariables() {
			return this.showVariablePermission && this.workspace.ativo;
		},
		canViewEquivalences() {
			return this.showEquivalencesPermission && this.workspace.ativo;
		},
		canViewLogs() {
			return this.showLogsPermission && this.workspace.ativo;
		},
		canViewExecutionLogs() {
			return this.showExecutionLogPermission && this.workspace.ativo;
		},
		canViewTeams() {
			return this.showTeamsPermission && this.isAdmin;
		},
		canViewUsers() {
			return this.showUsersPermission && this.isAdmin;
		},
		canViewUserProfiles() {
			return this.showUserProfilePermission && this.isAdmin;
		},
		canManageIntegrations() {
			return this.manageIntegrations;
		},
		canViewWorkspace() {
			return this.showWorkspace;
		},
		
		canViewExecutionQueue() {
			return this.showExecutionQueue;
		},

		canViewIntegrationPanel() {
			return this.accessPanelIntegrationPermission && this.isAdmin;
		},
		canViewAdminPanelIntegration() {
			return this.showAdminPanelIntegrationPermission && this.isAdmin;
		},

		canAccessStore() {
			return this.accessStore;
		},

		canManageRequestIntegration() {
			return this.manageRequestIntegrations;
		},
	},
});
