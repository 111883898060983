import Vue from 'vue';
import { parse } from 'flatted';

import { Method } from 'axios';
import {
	IActivationError,
	IExecutionsCurrentSummaryExtended,
	IExecutionDeleteFilter,
	IExecutionPushResponse,
	IExecutionResponse,
	IExecutionFlattedResponse,
	IExecutionsListResponse,
	IExecutionsStopData,
	IStartRunData,
	IWorkflowDb,
	IWorkflowShortResponse,
	IRestApi,
	IWorkflowDataUpdate,
	INodeTranslationHeaders,
} from '@/Interface';
import {
	IDataObject,
	INodeCredentials,
	INodeParameters,
	INodePropertyOptions,
	INodeTypeDescription,
	INodeTypeNameVersion,
} from 'n8n-workflow';
import { makeRestApiRequest, makeRestApiRequestN8n } from '@/api/helpers';

/**
 * Unflattens the Execution data.
 *
 * @export
 * @param {IExecutionFlattedResponse} fullExecutionData The data to unflatten
 * @returns {IExecutionResponse}
 */
function unflattenExecutionData (fullExecutionData: IExecutionFlattedResponse, parseExecution: Boolean): IExecutionResponse {
	// Unflatten the data
	const returnData: IExecutionResponse = {
		...fullExecutionData,
	};
	returnData.finished = returnData.finished ? returnData.finished : false;
	return returnData;
}

export const restApi = Vue.extend({
	methods: {
		restApi (): IRestApi {
			const self = this;
			return {
				async makeRestApiRequest (method: Method, endpoint: string, data?: IDataObject): Promise<any> { // tslint:disable-line:no-any
					return makeRestApiRequest(self.$store.getters.getRestApiContext, method, endpoint, data);
				},
				async makeRestApiRequestN8n (method: Method, endpoint: string, data?: IDataObject): Promise<any> { // tslint:disable-line:no-any
					return makeRestApiRequestN8n(self.$store.getters.getRestApiContext, method, endpoint, data);
				},
				getActiveWorkflows: (): Promise<string[]> => {
					return self.restApi().makeRestApiRequest('GET', `/active`);
				},
				getActivationError: (id: string): Promise<IActivationError | undefined> => {
					return self.restApi().makeRestApiRequest('GET', `/active/error/${id}`);
				},
				getCurrentExecutions: (filter: object): Promise<IExecutionsCurrentSummaryExtended[]> => {
					let sendData = {};
					if (filter) {
						sendData = {
							filter,
						};
					}
					return self.restApi().makeRestApiRequest('GET', `/executions-current`, sendData);
				},
				stopCurrentExecution: (executionId: string): Promise<IExecutionsStopData> => {
					return self.restApi().makeRestApiRequestN8n('POST', `/executions-current/${executionId}/stop`);
				},

				getCredentialTranslation: (credentialType): Promise<object> => {
					return self.restApi().makeRestApiRequestN8n('GET', '/credential-translation', { credentialType });
				},

				getCredentials: (workspaceId: string, query?: object): Promise<IWorkflowShortResponse[]> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/Credenciais`, query);
				},
				getCredentialById: (workspaceId: string, id: string,query?: object): Promise<IWorkflowShortResponse[]> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/Credenciais/${id}`, query);
				},

				getEquivalences: (workspaceId: string, query?: object): Promise<IWorkflowShortResponse[]> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/Equivalencias/`, query);
				},

				getEquivalenceRegister: (workspaceId: string, id: string, query?: object): Promise<IWorkflowShortResponse[]> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/Equivalencias/${id}/Valores`, query);
				},

				getEquivalenceById: (workspaceId: string, id: string, query?: object): Promise<IWorkflowShortResponse[]> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/Equivalencias/${id}`, query);
				},

				getVariableById: (id: string, query?: object): Promise<IWorkflowShortResponse[]> => {
					return self.restApi().makeRestApiRequest('GET', `/Variable/${id}`, query);
				},

				getVariableDataFromWorkflow: (workspaceId: string, id: string, query?: object): Promise<IWorkflowShortResponse[]> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/Workflows/${id}/Variaveis`, query);
				},

				getShareData: (workspaceId: string, id: string, query?: object): Promise<IWorkflowShortResponse[]> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/Compartilhamentos/${id}/Permissoes`, query);
				},

				getNodeTranslationHeaders: (): Promise<INodeTranslationHeaders> => {
					return self.restApi().makeRestApiRequestN8n('GET', '/node-translation-headers');
				},

				// Returns all node-types
				getNodeTypes: (onlyLatest = false): Promise<INodeTypeDescription[]> => {
					return self.restApi().makeRestApiRequestN8n('GET', `/node-types`, {onlyLatest});
				},

				getNodesInformation: (nodeInfos: INodeTypeNameVersion[]): Promise<INodeTypeDescription[]> => {
					return self.restApi().makeRestApiRequestN8n('POST', `/node-types`, {nodeInfos});
				},

				// Returns all the parameter options from the server
				getNodeParameterOptions: (nodeTypeAndVersion: INodeTypeNameVersion, path: string, methodName: string, currentNodeParameters: INodeParameters, credentials?: INodeCredentials): Promise<INodePropertyOptions[]> => {
					const sendData = {
						nodeTypeAndVersion,
						path,
						methodName,
						credentials,
						currentNodeParameters,
					};
					return self.restApi().makeRestApiRequestN8n('GET', '/node-parameter-options', sendData);
				},

				// Removes a test webhook
				removeTestWebhook: (workflowId: string): Promise<boolean> => {
					return self.restApi().makeRestApiRequestN8n('DELETE', `/test-webhook/${workflowId}`);
				},

				// Execute a workflow pela Api AliareIntegra
				runWorkflow: async (workspaceId: string, startRunData: IStartRunData): Promise<IExecutionPushResponse> => {
					startRunData.sessionId = this.$store.getters.sessionId;
					return self.restApi().makeRestApiRequest('POST', `/Workspaces/${workspaceId}/workflows/Executar`,startRunData);
				},

				runWorkflowById: async (workspaceId: string, workflowId: string): Promise<IExecutionPushResponse> => {
					return self.restApi().makeRestApiRequest('POST', `/Workspaces/${workspaceId}/Workflows/${workflowId}/executar`);
				},

				// Creates new credentials
				createNewWorkflow: (workspaceId: string, sendData: IWorkflowDataUpdate): Promise<IWorkflowDb> => {
					return self.restApi().makeRestApiRequest('POST', `/Workspaces/${workspaceId}/Workflows`, sendData);
				},

				createNewShareObject: (workspaceId: string, sendData: object): Promise<IWorkflowDb> => {
					return self.restApi().makeRestApiRequest('POST', `/Workspaces/${workspaceId}/Compartilhamentos`, sendData);
				},

				changeTypeExecutionWorkflow: (workflowId, status): Promise<INodeTranslationHeaders> => {
					return self.restApi().makeRestApiRequest('PATCH', `/Workflows/${workflowId}/Automatico`, { automatico: status});
				},

				getWorkflowsDetails: (workspaceId: string, params?: object): Promise<IWorkflowDb> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/Workflows/Detalhes`, params);
				},

				attachedWorkflows: (workspaceId: string, id: string, sendData?: object): Promise<IWorkflowDb> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/Workflows/${id}/Anexados`, sendData);
				},
				attachedExecutions: (workspaceId: string, id: string, sendData?: object): Promise<IWorkflowDb> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/Execucoes/${id}/ExecucoesAnexadas`, sendData);
				},

				duplicateWorkflow: (workspaceId: string, id: string, sendData: IWorkflowDataUpdate): Promise<IWorkflowDb> => {
					return self.restApi().makeRestApiRequest('POST', `/Workspaces/${workspaceId}/Workflows/${id}/Duplicar`, sendData);
				},
				duplicateMultipleWorkflow: (workspaceId: string, sendData: IWorkflowDataUpdate): Promise<IWorkflowDb> => {
					return self.restApi().makeRestApiRequest('POST', `/Workspaces/${workspaceId}/Workflows/Duplicar`, sendData);
				},

				createNewDataCenter: (workspaceId: string, sendData: IWorkflowDataUpdate): Promise<IWorkflowDb> => {
					return self.restApi().makeRestApiRequest('POST', `/Workspaces/${workspaceId}/FonteDeDados`, sendData);
				},

				getResourcesDataCenter: (workspaceId: string, dataCenterId: string, resourceId: string): Promise<IWorkflowShortResponse[]> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/FonteDeDados/${dataCenterId}/recursos/${resourceId}/valores`);
				},

				editDataCenter: (workspaceId: string, id: string, sendData: IWorkflowDataUpdate): Promise<IWorkflowDb> => {
					return self.restApi().makeRestApiRequest('PUT', `/Workspaces/${workspaceId}/FonteDeDados/${id}`, sendData);
				},

				createNewIntegration: (sendData: any): Promise<any> => {
					return self.restApi().makeRestApiRequest('POST', `/Integracoes`, sendData);
				},
				patchIntegration: (integrationId: string, sendData: any): Promise<any> => {
					return self.restApi().makeRestApiRequest('PATCH', `/Integracoes/${integrationId}`, sendData);
				},
				publishIntegration: (integrationId: string, sendData: any): Promise<any> => {
					return self.restApi().makeRestApiRequest('POST', `/Integracoes/${integrationId}/Publicar`, sendData);
				},

				getPublishedIntegrations: (sendData?: object): Promise<any> => {
					return self.restApi().makeRestApiRequest('GET', `/Integracoes/Publicacoes`, sendData);
				},

				getIntegrationById: (integrationId: string, sendData?: object): Promise<any> => {
					return self.restApi().makeRestApiRequest('GET', `/Integracoes/${integrationId}`, sendData);
				},

				getPublishedIntegrationById: (integrationId: string, sendData?: object): Promise<any> => {
					return self.restApi().makeRestApiRequest('GET', `/Integracoes/${integrationId}/Publicacao`, sendData);
				},

				createNewRegister: (workspaceId: string, idEquivalence: String, sendData: any): Promise<any> => {
					return self.restApi().makeRestApiRequest('POST', `/Workspaces/${workspaceId}/Equivalencias/${idEquivalence}/Valores`, sendData);
				},

				createNewVariable: (workspaceId: string, payload: any): Promise<any> => {
					return self.restApi().makeRestApiRequest('POST', `/Workspaces/${workspaceId}/Variaveis/`, payload);
				},

				createNewEquivalence: (workspaceId: string, sendData: any): Promise<any> => {
					return self.restApi().makeRestApiRequest('POST', `/Workspaces/${workspaceId}/Equivalencias`, sendData);
				},

				// Updates an existing workflow
				updateWorkflow: (workspaceId: string, id: string, data: IWorkflowDataUpdate): Promise<IWorkflowDb> => {
					return self.restApi().makeRestApiRequest('PATCH', `/Workspaces/${workspaceId}/Workflows/${id}`, data);
				},

				updateStatusDataCenter: (workspaceId: string, id: string, data: IWorkflowDataUpdate): Promise<IWorkflowDb> => {
					return self.restApi().makeRestApiRequest('PATCH', `/Workspaces/${workspaceId}/FonteDeDados/${id}/Ativo`, data);
				},

				updateVariable: (workspaceId: string, data: any): Promise<any> => {
					return self.restApi().makeRestApiRequest('PATCH', `/Workspaces/${workspaceId}/Variaveis/${data.id}`, data);
				},

				// Updates an existing register of equivalence
				updateRegisterEquivalence: (workspaceId: string, idRegister: string, idEquivalence: string, data: any): Promise<any> => {
					return self.restApi().makeRestApiRequest('PATCH', `/Workspaces/${workspaceId}/Equivalencias/${idEquivalence}/Valores/${idRegister}`, data);
				},

				// Updates an equivalence
				updateEquivalence: (workspaceId: string, idEquivalence: String, data: any): Promise<any> => {
					return self.restApi().makeRestApiRequest('PATCH', `/Workspaces/${workspaceId}/Equivalencias/${idEquivalence}/`, data);
				},

				putEquivalence: (workspaceId: string, idEquivalence: String, data: any): Promise<any> => {
					return self.restApi().makeRestApiRequest('PUT', `/Workspaces/${workspaceId}/Equivalencias/${idEquivalence}/`, data);
				},

				// Deletes a workflow
				deleteWorkflow: (workspaceId: string, name: string): Promise<void> => {
					return self.restApi().makeRestApiRequest('DELETE', `/Workspaces/${workspaceId}/workflows/${name}`);
				},

				deleteMultipleWorkflow: (workspaceId: string, query?: object): Promise<any> => {
					return self.restApi().makeRestApiRequest('POST', `/Workspaces/${workspaceId}/Workflows/Excluir`, query);
				},

				deleteShare: (workspaceId: string, data: object): Promise<void> => {
					return self.restApi().makeRestApiRequest('DELETE', `/Workspaces/${workspaceId}/Compartilhamentos/`, data);
				},

				// Deletes a equivalence
				deleteEquivalence: (workspaceId: string, id: String): Promise<void> => {
					return self.restApi().makeRestApiRequest('DELETE', `/Workspaces/${workspaceId}/Equivalencias/${id}`);
				},

				// Deletes a register of equivalence
				deleteRegisterEquivalence: (workspaceId: string, idRegister: String, idEquivalence: String): Promise<void> => {
					return self.restApi().makeRestApiRequest('DELETE', `/Workspaces/${workspaceId}/Equivalencias/${idEquivalence}/Valores/${idRegister}`);
				},

				// Returns the workflow with the given name
				getWorkflow: (workspaceId: string, id: string): Promise<IWorkflowDb> => {
					return self.restApi().makeRestApiRequest('GET', `Workspaces/${workspaceId}/workflows/${id}`);
				},

				// Returns the history-version of workflow
				getWorkflowHistoryVersion: (workspaceId: string, id: string, query?: object): Promise<IWorkflowDb> => {
					return self.restApi().makeRestApiRequest('GET', `Workspaces/${workspaceId}/Workflows/${id}/HistoricoVersoes`, query);
				},

				getDataCenter: (workspaceId: string, query?: object): Promise<any> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/FonteDeDados`, query);
				},

				getDataCenterById: (workspaceId: string, id: string): Promise<any> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/FonteDeDados/${id}`);
				},

				deleteDataCenter: (workspaceId: string, id: string): Promise<any> => {
					return self.restApi().makeRestApiRequest('DELETE', `/Workspaces/${workspaceId}/FonteDeDados/${id}`);
				},

				// Returns all saved workflows
				getWorkflows: (workspaceId: string, query?: object): Promise<IWorkflowShortResponse[]> => {
					return self.restApi().makeRestApiRequest('GET', `Workspaces/${workspaceId}/Workflows`, query);
				},

				// Returns a workflow from a given URL
				getWorkflowFromUrl: (url: string): Promise<IWorkflowDb> => {
					return self.restApi().makeRestApiRequest('GET', `/workflows/from-url`, { url });
				},

				// Returns the execution with the given ID
				getExecutionData: async (workspaceId: string, id: string): Promise<IExecutionResponse> => {
					const response = await self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/Execucoes/${id}/Dados`) as IExecutionFlattedResponse;
					let dataParsed = parse(response);
					let data = {};
					data.data = dataParsed;
					return unflattenExecutionData(data);
				},

				getExecutionDataByDataUrl: async (dataExecution: object): Promise<IExecutionResponse> => {
					const response = await fetch(dataExecution.dataUrl);
					let data = await response.json();
					data.data = parse(data.data);
					return unflattenExecutionData(data);
				},

				// Returns the version with the given ID
				getVersionData: async (workspaceId: string, versionId: string, workflowId: String): Promise<IExecutionResponse> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/Workflows/${workflowId}/Versao/${versionId}`);
				},


				// Returns the execution with the given ID
				getExecutionById: async (workspaceId: string, id: string): Promise<IExecutionResponse> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/Execucoes/${id}`);
				},

				getTimezone: async (): Promise<IExecutionResponse> => {
					return self.restApi().makeRestApiRequest('GET', `/FusoHorario/`);
				},

				getExecutions: async (workspaceId: string, query?: object): Promise<IExecutionResponse> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/Execucoes/`, query);
				},

				getExecutionQueue: async (): Promise<IExecutionResponse> => {
					return self.restApi().makeRestApiRequest('GET', `/FilaDeExecucoes`);
				},

				stopExecutionById: (workspaceId, executionId): Promise<void> => {
					return self.restApi().makeRestApiRequest('POST', `/Workspaces/${workspaceId}/Execucoes/${executionId}/Parar`);
				},

				stopImmediateExecutionById: (workspaceId, executionId): Promise<void> => {
					return self.restApi().makeRestApiRequest('POST', `/Workspaces/${workspaceId}/Execucoes/${executionId}/Interromper`);
				},


				// Deletes executions
				deleteExecutions: (sendData: IExecutionDeleteFilter): Promise<void> => {
					return self.restApi().makeRestApiRequest('POST', `/executions/delete`, sendData);
				},

				// Returns the execution with the given name
				retryExecution: (id: string, loadWorkflow?: boolean): Promise<boolean> => {
					let sendData;
					if (loadWorkflow === true) {
						sendData = {
							loadWorkflow: true,
						};
					}
					return self.restApi().makeRestApiRequestN8n('POST', `/executions/${id}/retry`, sendData);
				},

				postProject: (workspaceId: string, query?: object): Promise<any> => {
					return self.restApi().makeRestApiRequest('POST', `/Workspaces/${workspaceId}/Projetos`, query);
				},

				patchProject: (workspaceId: string, projetoId: string, data: any): Promise<any> => {
					return self.restApi().makeRestApiRequest('PATCH', `/Workspaces/${workspaceId}/Projetos/${projetoId}`, data);
				},

				// Returns the execution with the given ID
				duplicateProject: (workspaceId: string, projetoId: string, query?: object): Promise<any> => {
					return self.restApi().makeRestApiRequest('POST', `/Workspaces/${workspaceId}/Projetos/${projetoId}/Duplicar`, query);
				},

				duplicateMultipleProject: (workspaceId: string, query?: object): Promise<any> => {
					return self.restApi().makeRestApiRequest('POST', `/Workspaces/${workspaceId}/Projetos/Duplicar`, query);
				},

				deleteProjects: (workspaceId: string, query?: object): Promise<any> => {
					return self.restApi().makeRestApiRequest('POST', `/Workspaces/${workspaceId}/Projetos/Excluir`, query);
				},

				activeMultipleProjects: (workspaceId: string, ids: [string]): Promise<any> => {
					return self.restApi().makeRestApiRequest('POST', `/Workspaces/${workspaceId}/Projetos/Ativar`, {ids, ativo: true});
				},
				inactiveMultipleProjects: (workspaceId: string, ids: [string]): Promise<any> => {
					return self.restApi().makeRestApiRequest('POST', `/Workspaces/${workspaceId}/Projetos/Ativar`, {ids, ativo: false});
				},
				activeMultipleWorkflows: (workspaceId: string, ids: [string]): Promise<any> => {
					return self.restApi().makeRestApiRequest('POST', `/Workspaces/${workspaceId}/Workflows/Ativar`, {ids, ativo: true});
				},
				inactiveMultipleWorkflows: (workspaceId: string, ids: [string]): Promise<any> => {
					return self.restApi().makeRestApiRequest('POST', `/Workspaces/${workspaceId}/Workflows/Ativar`, {ids, ativo: false});
				},
				getProjectDetailingById: (workspaceId: string, id: string): Promise<any> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/Projetos/${id}`);
				},

				getProjects: (workspaceId: string, query?: object): Promise<IWorkflowShortResponse[]> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/Projetos`, query);
				},

				// Returns all saved executions
				// TODO: For sure needs some kind of default filter like last day, with max 10 results, ...
				getPastExecutions: (filter: object, limit: number, lastId?: string | number, firstId?: string | number): Promise<IExecutionsListResponse> => {
					let sendData = {};
					if (filter) {
						sendData = {
							filter,
							firstId,
							lastId,
							limit,
						};
					}

					return self.restApi().makeRestApiRequestN8n('GET', `/executions`, sendData);
				},

				// Returns all the available timezones
				getTimezones: (): Promise<IDataObject> => {
					return self.restApi().makeRestApiRequestN8n('GET', `/options/timezones`);
				},

				// Binary data
				getBinaryBufferString: (dataPath: string): Promise<string> => {
					return self.restApi().makeRestApiRequestN8n('GET', `/data/${dataPath}`);
				},

				getWorkspaces: (query?: object): Promise<any> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces`, query);
				},
				verifyNameAvailableWorkspace:(nome: string): Promise<any> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/VerificarNomeDisponivel`, {
						nome,
					});
				},
				verifyTitleAvailableIntegration:(titulo: string): Promise<any> => {
					return self.restApi().makeRestApiRequest('GET', `/Integracoes/VerificarTituloDisponivel`, {
						titulo,
					});
				},
				getWorkspaceById: (workspaceId: string, query?: object): Promise<any> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}`, query);
				},

				sendIdPanelIntegration: (idPanel: string, query?: object): Promise<any> => {
					return self.restApi().makeRestApiRequest('POST', `/PainelIntegracoes`, {painel: idPanel, parametros: query || null});
				},

				getUsersInWorkspace: (workspaceId: string): Promise<any> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/Usuarios`);
				},

				createWorkspace: (sendData: any): Promise<any> => {
					return self.restApi().makeRestApiRequest('POST', `/Workspaces/`, sendData);
				},

				editWorkspace: (workspaceId: string, sendData: any): Promise<any> => {
					return self.restApi().makeRestApiRequest('PUT', `/Workspaces/${workspaceId}`, sendData);
				},

				deleteWorkspaces: (workspaceId: string): Promise<any> => {
					return self.restApi().makeRestApiRequest('DELETE', `/Workspaces/${workspaceId}`);
				},

				updateWorkspaces: (workspaceId: string, data: any): Promise<any> => {
					return self.restApi().makeRestApiRequest('PATCH', `/Workspaces/${workspaceId}/Ativo`, data);
				},

				checkAvailableNameProject: (workspaceId: string, query?: object): Promise<any> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/Projetos/VerificarNomeDisponivel`, query);
				},

				checkAvailableNameCredential: (workspaceId: string, query?: object): Promise<any> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/Credenciais/VerificarNomeDisponivel`, query);
				},

				checkAvailableNameWorkflows: (workspaceId: string, projetoId: string, query?: object): Promise<any> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/Workflows/${projetoId}/VerificarNomeDisponivel`, query);
				},

				checkAvailableNameDataCenter: (workspaceId: string, query?: object): Promise<any> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/FonteDeDados/VerificarNomeDisponivel`, query);
				},

				checkAvailableNameEquivalences: (workspaceId: string, query?: object): Promise<any> => {
					return self.restApi().makeRestApiRequest('GET', `/Workspaces/${workspaceId}/Equivalencias/VerificarNomeDisponivel`, query);
				},
				getIntegrations: (query?: object): Promise<any> => {
					return self.restApi().makeRestApiRequest('GET', `/Integracoes`, query);
				},
				deleteIntegrations: (integracaoId: string): Promise<any> => {
					return self.restApi().makeRestApiRequest('DELETE', `/Integracoes/${integracaoId}`);
				},
				suspendIntegrationPublished: (integracaoId: string): Promise<any> => {
					return self.restApi().makeRestApiRequest('DELETE', `/Integracoes/${integracaoId}/Publicacao`);
				},
				importPublishedIntegrations: (integracaoId: string, data: any): Promise<any> => {
					return self.restApi().makeRestApiRequest('POST', `/Integracoes/${integracaoId}/Importar`, data);
				},
				getImportRequests: (query?: object): Promise<any> => {
					return self.restApi().makeRestApiRequest('GET', `/SolicitacoesDeImportacao`, query);
				},
				postStatusIntegration: (solicitacaoId: string, action: string): Promise<any> => {
					return self.restApi().makeRestApiRequest('POST', `/SolicitacoesDeImportacao/${solicitacaoId}/${action}`);
				},
				postRequestIntegrations: (data: any): Promise<any> => {
					return self.restApi().makeRestApiRequest('POST', `/SolicitacoesDeImportacao`, data);
				},
			};
		},
	},
});
