
import Table from '@/components/Table.vue';
import FilterBar from '@/components/FilterBar.vue';
import FilterIcon from '@/components/FilterIcon.vue';
import TimetableFilter from '@/components/TimetableFilter.vue';
import SelectFilter from '@/components/SelectFilter.vue';
import BackgroundView from '@/components/BackgroundView.vue';
import InteractionMenu from '@/components/InteractionMenu.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';

import mixins from 'vue-typed-mixins';
import { restApi } from '@/components/mixins/restApi';
import { removeItemFromArrayByValue } from '@/components/helpers';
import { executionQueuePermission } from '@/components/mixins/permissions/executionQueue';

import moment from 'moment';
import WebStorage from '@/common/WebStorage';
import { mapActions, mapGetters, mapState } from 'vuex';

export default mixins(
	restApi,
	executionQueuePermission,
).extend({
	components: {
		Table,
		FilterBar,
		TimetableFilter,
		FilterIcon,
		SelectFilter,
		BackgroundView,
		InteractionMenu,
		ModalButtonCancel,
		ModalButtonSuccess,
		AlertNotificationModal,
	},
	name: 'ExecutionQueue',
	data() {
		return {
			showAlert: {
				active: false,
				data: {},
			},
			batchStop: false,
			isLoadingPage: false,
			isLoadingTable: false,
			executionsSelected: [],

			listExecutions: [],
			execucoesParando: JSON.parse(WebStorage.getItem('execucoesParando')) || [],
			intervalTimer: null,
			refreshInterval: null,

			applyPage: '',
			filtering: false,
			filtersActive: {},
			clearfiltering: false,
			queryTime: this.$route.query.timestamp || '',
			queryResults: typeof this.$route.query.resultadoExecucao == 'string' ? [this.$route.query.resultadoExecucao] : this.$route.query.resultadoExecucao || [],
			queryTrigger: this.$route.query.gatilho || [],
		};
	},
	computed: {
		...mapGetters('ui', ['sidebarMenuCollapsed']),
		workspace() {
			return this.$store.getters.workspace;
		},

		allExecutionsSelected() {
			return this.executionsAbleToSelect == this.executionsSelected.length;
		},
		partialExecutionsSelected() {
			return this.executionsAbleToSelect != this.executionsSelected.length && this.executionsSelected.length > 0;
		},
		executionsAbleToSelect() {
			return this.listExecutions?.filaDeExecucoes?.length;
		},

		discardModalText() {
			return this.$locale.baseText('Continuar Executando');
		},
		keepCloseText() {
			return 'Interromper Execução';
		},
		keepModalText() {
			return this.$locale.baseText('Parar Execução');
		},
		alertNotificationTitle() {
			return this.batchStop ? this.$locale.baseText('Parar Execuções dos Fluxos') : this.$locale.baseText('Parar Execução do Fluxo ');
		},
		alertNotificationDescription() {
			if(this.batchStop) {
				return `A <b>interrupção</b> da execução  de um fluxo pode ocasionar <b>inconsistências</b> nos <b>dados</b> do sistema de destino. Caso queira, poderá <b>parar</b> a execução na <b>transição</b> para o <b>proximo conector</b> ou <b>interromper imediatamente</b> sem aguardar que a <b>transição</b> ocorra. O que você deseja fazer?`;

			} else {
				return `A <b>interrupção</b> da execução  de um fluxo pode ocasionar <b>inconsistências</b> nos <b>dados</b> do sistema de destino. Caso queira, poderá <b>parar</b> a execução na <b>transição</b> para o <b>proximo conector</b> ou <b>interromper imediatamente</b> sem aguardar que a <b>transição</b> ocorra. O que você deseja fazer?`;
			}
		},
		triggerText() {
			if (typeof this.queryTrigger === 'string') {
				return this.queryTrigger;
			}
			const length = this.queryTrigger.length;
			return length === 1 ? this.queryTrigger[0] : length === 0 ? 'Modo de Disparo' : `${this.queryTrigger[0]} +${length - 1}`;
		},

		resultText() {
			if (typeof this.queryResults === 'string') {
				return this.queryResults;
			}
			const length = this.queryResults.length;
			return length === 1 ? this.queryResults[0] : length === 0 ? 'Status' : `${this.queryResults[0]} +${length - 1}`;
		},
		intervalDateText() {
			if (this.queryTime === '' || this.queryTime === undefined || this.queryTime === null) {
					return 'Horários dos agendamentos';
			}
			return this.formatarHoraMinuto(this.queryTime);
		},
		emptyList() {
			return 'Não há execuções na fila. A medida que forem adicionadas, as informações serão apresentadas aqui.'
		},

		executionsInProgess() {
			return this.listExecutions.totalExecutando == 1 ? 'Execução em andamento' : 'Execuções em andamento';
		},
		executionsNotStarted() {
			return this.listExecutions.totalAguardando == 1 ? 'Execução não iniciada' : 'Execuções não iniciadas';
		},
		executionsStopping() {
			return this.executionsStoppingLength == 1 ? 'Execução parando' : 'Execuções parando';
		},
		executionsStoppingLength() {
			const stoppingLength = this.listExecutions.filaDeExecucoes?.filter(function(execucao) {
				return execucao.status == 'Parando';
			});

			return stoppingLength?.length;
		},
		selectedExecution() {
			return this.executionsSelected.length == 1 ? 'Execução selecionada' : 'Execuções selecionadas';
		}
	},
	methods: {
		...mapActions('aliareAccount', ['getUsersByTenantId', 'verifyUserActive', 'getUserPermissionById']),

		closeAlert() {
			this.showAlert.active = false;
			this.batchStop = false;
			this.showAlert.data = {};
		},

		async stopExecution(options: {stopImmediate: Boolean}) {
			this.showAlert.active = false;
			let payload = {};
			if (this.batchStop) {
				payload.id = [...this.executionsSelected];
			} else {
				payload.id = [this.showAlert.data.id];
			}
			payload.id.forEach(async (executionId) =>{
				let execucao = this.listExecutions.filaDeExecucoes.find(execution => execution.execucaoId == executionId);
				const oldStatus = execucao.status;
				try {
					execucao.isStoping = true;
					execucao.status = 'Parando';

					if (options?.stopImmediate) {
						await this.restApi().stopImmediateExecutionById(execucao.workspace.id, executionId);
					} else {
						await this.restApi().stopExecutionById(execucao.workspace.id, executionId);
					}

					this.execucoesParando.push(execucao);
					WebStorage.setItem('execucoesParando', JSON.stringify(this.execucoesParando));
				} catch (e) {
					execucao.status = oldStatus;
					execucao.isStoping = false;
					console.error(e);
				}
			});

			this.batchStop = false;
			this.executionsSelected = [];
		},

		async initPageExecutionQueue() {
			this.applyPage = WebStorage.getItem('applyPage');

			if(this.applyPage == 'executionQueue') {
				this.$router.replace({query: { ...WebStorage.getItem('setFilters') }}).catch(() => {});

				this.queryTime = this.$route.query.timestamp;
			}


			await this.getExecutionQueue()
		},

		openModalAlert(execution) {
			this.showAlert.active = true;
			this.showAlert.data.id = execution.execucaoId;
		},
		openAlertBatchStop() {
			this.batchStop = true;
			this.showAlert.active = true;
		},

		formatarData(dataString) {
			const dataMomentUTC = moment.utc(dataString);
			const dataFormatada =  moment(dataMomentUTC).local().format("HH[h]:mm[min]");
			return dataFormatada;
		},
		formatDate(date) {
			let dateUtc = moment.utc(date);
			let localDate = moment(dateUtc).local().format('DD/MM/YYYY [às] HH[h]:mm[min]');
			return localDate;
		},
		formatarHoraMinuto(timestamp) {
			if (timestamp === undefined || timestamp === null || timestamp === '') {
				return '';
			}

			const formatoDesejado = 'HH[h]:mm[min]';
			const partesTimestamp = timestamp.split(':');
			const momentoFormatado = moment({ hour: partesTimestamp[0], minute: partesTimestamp[1] }).format(formatoDesejado);
			return momentoFormatado;
		},

		async filterBySearch(search) {
			this.filtering = search.length < 1 ? true : false;
			this.addOrRemoveFilter(this.filtersActive, 'filterSearch', search.length < 1 ? '' : search);
		},
		async filterByResult(query) {
			this.queryResults = query;
			this.addOrRemoveFilter(this.filtersActive, 'filterResults', query);
			this.filtering = !this.queryResults.length;
		},
		async filterByTrigger(query) {
			this.queryTrigger = query;
			this.addOrRemoveFilter(this.filtersActive, 'filterTrigger', query);
			this.filtering = !this.queryTrigger.length;
		},
		async filterByTime(time) {
			this.queryTime = time;
			this.filtering = (this.queryTime === '');

			this.addOrRemoveFilter(this.filtersActive, 'filterTime', this.queryTime);
		},

		async filterTable(filters) {
			try {
				this.isLoading = true;
				const routeQuery = this.$route.query;
				const payload = {
					timestamp: filters?.filterTime || routeQuery?.timestamp,
					search: filters?.filterSearch || routeQuery?.search,
					resultadoExecucao: filters?.filterResults || routeQuery?.resultadoExecucao,
					gatilho: filters?.filterTrigger || routeQuery?.gatilho,
				};

				this.$router.replace({query: { ...payload }}).catch(() => {});

				const filteredData = this.verifyFilter(this.listExecutions.filaDeExecucoes, payload);
				await this.applyFilter(filteredData, payload);
			} catch (e) {
				this.$store.commit('activeAlert', {
					message: e.notifications[0],
					status: 'error',
				});
				this.clearfiltering = true;
			} finally {
				this.isLoading = false;
			}
		},
		verifyFilter(data, payload) {
			return data.filter(item => {
				const searchLower = payload.search ? payload.search.toLowerCase() : '';

				const isSearchMatch =
					!searchLower ||
					item.workflow.nome.toLowerCase().includes(searchLower) ||
					item.workspace.nome.toLowerCase().includes(searchLower) ||
					item.projeto.nome.toLowerCase().includes(searchLower) ||
					item.tenant.nome.toLowerCase().includes(searchLower);

				const isResultadoMatch =
				!payload.resultadoExecucao ||
				payload.resultadoExecucao.some(status => {
					return item?.status?.toLowerCase().includes(status.toLowerCase());
				});


				const isTriggerMatch =
				!payload.gatilho ||
				payload.gatilho.some(gatilho => {
					return item?.modoExecucao?.toLowerCase().includes(gatilho.toLowerCase());
				});


				const horaMinutoTimestamp = this.formatarData(item.agendamento);
				const horaMinutoDesejado = payload.timestamp ? this.formatarHoraMinuto(payload.timestamp) : '';
				const isHoraMinutoMatch = !payload.timestamp ||
					(horaMinutoDesejado && horaMinutoDesejado === horaMinutoTimestamp);

				return isSearchMatch && isResultadoMatch && isHoraMinutoMatch && isTriggerMatch;
			});
		},
		async applyFilter(dataFiltered, query) {
			this.filtering = true;
			this.clearfiltering = true;
			this.listExecutions.filaDeExecucoes = dataFiltered;

			WebStorage.setItem('setFilters', query);
			WebStorage.setItem('applyPage', 'executionQueue');
		},

		async clearFilter() {
			this.filtering = false;
			this.filtersActive = {};
			this.clearfiltering = false;
			this.queryResults = [];
			this.queryTrigger = [];
			this.queryTime = '';

			await this.$router.replace({'query': null}).catch(() => {});

			WebStorage.removeItem('applyPage');
			WebStorage.removeItem('setFilters');
			this.initPageExecutionQueue();
		},
		addOrRemoveFilter(object, property, value) {
			if ((Array.isArray(value) && value.length === 0) || value === '') {
				this.$delete(object, property);
			} else {
				this.$set(object, property, value);
			}
		},

		addExecutionToSelect(execution) {
			if(execution.status == 'Parando') return;

			if (this.verifyExecutionSelected(execution.execucaoId)) {
				removeItemFromArrayByValue(this.executionsSelected, execution.execucaoId);
			} else {
				this.executionsSelected.push(execution.execucaoId);
			}
		},
		verifyExecutionSelected(verifyExecutionId) {
			return this.executionsSelected.find(execucaoId => execucaoId === verifyExecutionId);
		},
		selectAllExecutions() {
			if (this.partialExecutionsSelected || this.allExecutionsSelected) {
				this.executionsSelected = [];
			} else {
				this.listExecutions.filaDeExecucoes.forEach((execution) =>{
					if (execution.status == 'Parando') return;
					this.addExecutionToSelect(execution);
				});
			}
		},

		async getExecutionQueue() {
			try {
				const executions = await this.restApi().getExecutionQueue();
				this.listExecutions = executions;

				if (this.execucoesParando.length > 0) {
					this.execucoesParando.forEach(execucaoParando => {
						const execucaoExistente = this.listExecutions.filaDeExecucoes.find(execucao => execucao.execucaoId === execucaoParando.execucaoId);
						if (execucaoExistente) {
						execucaoExistente.status = execucaoParando.status;
						}
					});
				}

				await Promise.all(this.listExecutions.filaDeExecucoes.map(async (item) => {
					if (item.status === 'Executando') {
						this.$set(item, 'tempoDecorrido', this.getTempoDuracao(item));
						await this.iniciarTemporizador(item);
					}
				}));

				if (Object.keys(this.$route.query).length && this.applyPage == 'executionQueue') {
					this.filterTable();
				}
			} catch (error) {
				console.error('error', error);
			}
		},

		getTempoDuracao(item) {
			const currentUTCTime = moment.utc();
			const inicioExecucao = moment.utc(item.inicioExecucao);
			const elapsedTime = currentUTCTime.diff(inicioExecucao);

			const duration = moment.duration(elapsedTime);
			const hours = Math.floor(duration.asHours());
			const minutes = moment.utc(elapsedTime).format("mm");
			const seconds = moment.utc(elapsedTime).format("ss");

			return `${String(hours).padStart(2, '0')}h:${minutes}min:${seconds}s`;
		},
		iniciarTemporizador(item) {
			this.refreshInterval = window.setInterval(() => {
				item.tempoDecorrido = this.getTempoDuracao(item);
			}, 1000);
    },
	},
	async created() {
		this.isLoadingTable = true;
		await this.initPageExecutionQueue();
		this.isLoadingTable = false;
	},
	mounted() {
		this.intervalTimer = window.setInterval(() => {
			this.initPageExecutionQueue();
		}, 5000);
	},
	destroyed () {
		if (this.intervalTimer !== null) {
			clearInterval(this.intervalTimer);
		}

		if (this.refreshInterval !== null) {
			clearInterval(this.refreshInterval);
		}
	},
});
