<template>
	<div v-if="renderComponent">
		<div v-if="viewDataCard" class="projectDetailing__bodyCard">
			<div class="cardFlow__selectAll" v-show="selectMode" @click="emitSelectAll()">
				<i class="select-icon square" :class="{ selected: allWorkflowsSelected, partialSelected: partialWorkflowsSelected }"></i>
				<span>
					Selecionar todos
				</span>
			</div>
			<div
				v-for="(cell, index) in workflowData"
				:key="cell.id"
				class="projectDetailing__bodyCardsFrame"
				v-bind:class="{
					projectDetailing__bodyCardsFrameInactive: !cell.ativo,
					projectDetailing__bodyCardsFrameActive: cell.ativo,
				}"
			>
				<span class="tooltipAliare">
					<p v-if="canOpenFlow(projectActive)" class="info-title">{{ 'Acessar Fluxo' }}</p>
					<p class="info-organization">
						{{
							canOpenFlow(projectActive)
								? cell.nome
								: $locale.baseText('permission.noPermissionFuncionality')
						}}
					</p>
				</span>

				<div
					class="projectDetailing__bodyCardsFrameHeader"
					v-bind:class="{
						projectDetailing__bodyCardsFrameHeaderInactive: !cell.ativo,
						projectDetailing__bodyCardsFrameActive: cell.ativo,
					}"
				>
					<div
						class="projectDetailing__bodyCardsFrameHeaderAux"
						@click="canOpenFlow(projectActive) && redirectWorkflowExisting(cell.id, true)"
					>
						<div class="select-icon square" style="margin-left: 16px" @click="toggleSelectWorkflow(cell)" :class="{ selected: cell?.selectedWorkflow }" v-if="selectMode && canEditFlowInProjectDetail(cell, form)">
						</div>
						<div class="projectDetailing__bodyCardsFrameHeaderIcon" v-else></div>
						<div class="projectDetailing__bodyCardsFrameHeaderTitle">
							<p>{{ cell.nome }}</p>
						</div>
					</div>
					<div class="projectDetailing__bodyCardsFrameHeaderAux" v-if="!selectMode">
						<div class="projectDetailing__bodyCardsFrameHeaderOptions" @click="v_show(cell.id)" />

						<MenuOptions :Right="'5px'" :Top="'23px'" v-show="showFlow == cell.id">
							<template v-slot:top>
								<div class="projectDetailing__bodyCardsOptionsTop">
									<el-dropdown-item :disabled="!canEditFlowInProjectDetail(cell, form)">
										<el-tooltip
											:disabled="editFlowPermission"
											:content="$locale.baseText('permission.noPermissionFuncionality')"
											placement="top"
										>
											<div
												data-testid="button-open-flow"
												class="projectDetailing__bodyCardsOptions"
												@click="toggleSelectWorkflow(cell, true)"
												v-if="canEditFlowInProjectDetail(cell, form)"
											>
												<div class="select-icon square"></div>
												<div>{{ $locale.baseText('Selecionar Fluxo') }}</div>
											</div>
										</el-tooltip>
									</el-dropdown-item>

									<el-dropdown-item :disabled="!canEditFlowInProjectDetail(cell, form)">
										<el-tooltip
											:disabled="editFlowPermission"
											:content="$locale.baseText('permission.noPermissionFuncionality')"
											placement="top"
										>
											<div
												data-testid="button-open-flow"
												class="projectDetailing__bodyCardsOptions"
												@click="
													canEditFlowInProjectDetail(cell, form) &&
														redirectWorkflowExisting(cell.id)
												"
											>
												<div
													class="projectDetailing__bodyCardsOptions_Icon projectDetailing__bodyCardsOptions_Icon_0"
												></div>
												<div>{{ $locale.baseText('Editar Fluxo') }}</div>
											</div>
										</el-tooltip>
									</el-dropdown-item>

									<el-dropdown-item :disabled="!canRenameFlow(cell, form)">
										<el-tooltip
											:disabled="editFlowPermission"
											:content="$locale.baseText('permission.noPermissionFuncionality')"
											placement="top"
										>
											<div
												data-testid="button-open-flow"
												class="projectDetailing__bodyCardsOptions"
												@click="canRenameFlow(cell, form) && showModals(cell.id, 'renameWorkflow')"
											>
												<div
													class="projectDetailing__bodyCardsOptions_Icon projectDetailing__bodyCardsOptions_Icon_1"
												></div>
												<div>{{ $locale.baseText('projectDetailing.renameFlow') }}</div>
											</div>
										</el-tooltip>
									</el-dropdown-item>

									<el-dropdown-item :disabled="!canDuplicateFlow(cell, form)">
										<el-tooltip
											:disabled="duplicateFlowPermission"
											:content="$locale.baseText('permission.noPermissionFuncionality')"
											placement="top"
										>
											<div
												data-testid="button-open-modal-duplicate"
												class="projectDetailing__bodyCardsOptions"
												@click="
													canDuplicateFlow(cell, form) &&
														cell.ativo &&
														openModalDuplicateWorkflow(
															cell.id,
															organization?.length > 1 ? true : false,
														)
												"
											>
												<div
													class="projectDetailing__bodyCardsOptions_Icon projectDetailing__bodyCardsOptions_Icon_2"
												></div>
												<div>{{ $locale.baseText('projectDetailing.tect_2') }}</div>
											</div>
										</el-tooltip>
									</el-dropdown-item>

									<el-dropdown-item :disabled="!canAccessVersion(cell, projectActive)">
										<el-tooltip
											:disabled="editFlowPermission || openFlowPermission"
											:content="$locale.baseText('permission.noPermissionFuncionality')"
											placement="top"
										>
											<div
												class="projectDetailing__bodyCardsOptions"
												@click="
													canAccessVersion(cell, projectActive) &&
														showModals(cell.id, 'historyVersion')
												"
											>
												<div
													class="s projectDetailing__bodyCardsOptions_Icon projectDetailing__bodyCardsOptions_Icon_4"
												></div>
												<div>{{ $locale.baseText('projectDetailing.tect_4') }}</div>
											</div>
										</el-tooltip>
									</el-dropdown-item>

									<!-- <div class="projectDetailing__bodyCardsOptions">
													<div
														class="
															projectDetailing__bodyCardsOptions_Icon
															projectDetailing__bodyCardsOptions_Icon_3
														"
													></div>
													<div>{{ $locale.baseText('projectDetailing.tect_3') }}</div>
												</div> -->

									<el-dropdown-item :disabled="!canAccessExecutionFlow(cell, projectActive)">
										<el-tooltip
											:disabled="editFlowPermission || openFlowPermission"
											:content="$locale.baseText('permission.noPermissionFuncionality')"
											placement="top"
										>
											<div
												class="projectDetailing__bodyCardsOptions"
												@click="
													canAccessExecutionFlow(cell, projectActive) &&
														showModals(cell.id, 'executions')
												"
											>
												<div
													class="projectDetailing__bodyCardsOptions_Icon projectDetailing__bodyCardsOptions_Icon_5"
												></div>
												<div>{{ $locale.baseText('projectDetailing.tect_5') }}</div>
											</div>
										</el-tooltip>
									</el-dropdown-item>

									<el-dropdown-item :disabled="!canAccessAttachmentFlows(cell, projectActive)">
										<el-tooltip
											:disabled="editFlowPermission || openFlowPermission"
											:content="$locale.baseText('permission.noPermissionFuncionality')"
											placement="top"
										>
											<div
												class="projectDetailing__bodyCardsOptions"
												@click="
													canAccessAttachmentFlows(cell, projectActive) &&
														showModals(cell.id, 'attachedWorkflows')
												"
											>
												<div
													class="projectDetailing__bodyCardsOptions_Icon projectDetailing__bodyCardsOptions_Icon_8"
												></div>
												<div>{{ $locale.baseText('projectDetailing.tect_8') }}</div>
											</div>
										</el-tooltip>
									</el-dropdown-item>

									<!-- <el-dropdown-item :disabled="!canViewShareFlow(cell, projectActive)">
													<el-tooltip :disabled="canViewShareFlow(cell, projectActive)" placement="top">
														<div
															class="projectDetailing__bodyCardsOptions"
															@click="openShareModal({...cell, nomeProjeto: form.nome }, 'Workflow', !canShareFlow(cell, projectActive))"
														>
															<div
																class="projectDetailing__bodyCardsOptions_Icon projectDetailing__bodyCardsOptions_Icon_9"
															></div>
															<div>{{ $locale.baseText('project.share') }}</div>
														</div>
													</el-tooltip>
												</el-dropdown-item> -->

									<el-dropdown-item :disabled="!canManageVariablesFlow(cell, projectActive)">
										<el-tooltip
											:disabled="editFlowPermission || openFlowPermission"
											:content="$locale.baseText('permission.noPermissionFuncionality')"
											placement="top"
										>
											<div
												class="projectDetailing__bodyCardsOptions"
												@click="
													canManageVariablesFlow(cell, projectActive) &&
														showModals(cell.id, 'manageVariable')
												"
											>
												<div
													class="projectDetailing__bodyCardsOptions_Icon projectDetailing__bodyCardsOptions_Icon_6"
												></div>
												<div>{{ $locale.baseText('projectDetailing.tect_6') }}</div>
											</div>
										</el-tooltip>
									</el-dropdown-item>
								</div>
								<div class="division"></div>
							</template>
							<template v-slot:middle>
								<div class="projectDetailing__bodyCardsMiddle">
									<el-dropdown-item :disabled="!canActiveInactiveFlow(form)">
										<el-tooltip
											:disabled="editFlowPermission"
											:content="$locale.baseText('permission.noPermissionFuncionality')"
											placement="top"
										>
											<div class="options-middle">
												<div
													class="switch_box box_4"
													@click="canActiveInactiveFlow(form) && changeActive(!cell.ativo, cell)"
													:class="{'switch_box--disableButton': !canActiveInactiveFlow(form)}"
												>
													<div class="input_wrapper">
														<input type="checkbox" v-model="cell.ativo" class="switch_4" />
														<div class="content-switch is_checked">Ativo</div>
														<div class="content-switch is_unchecked">Inativo</div>
													</div>
												</div>
											</div>
										</el-tooltip>
									</el-dropdown-item>

									<el-dropdown-item :disabled="!canChangeAutomaticManualFlow(cell, form)">
										<el-tooltip
											:disabled="editFlowPermission"
											:content="$locale.baseText('permission.noPermissionFuncionality')"
											placement="top"
										>
											<div class="options-middle">
												<div
													class="switch_box box_4"
													@click="canChangeAutomaticManualFlow(cell, form) && changeExecution(!cell.automatico, cell)"
													:class="{'switch_box--disableButton': !canChangeAutomaticManualFlow(cell, form)}"
												>
													<div class="input_wrapper">
														<input type="checkbox" v-model="cell.automatico" class="switch_4" />
														<div class="content-switch is_checked isAutomatic">Automática</div>
														<div class="content-switch is_unchecked">Manual</div>
													</div>
												</div>
											</div>
										</el-tooltip>
									</el-dropdown-item>
								</div>
							</template>
							<template v-slot:end>
								<el-dropdown-item :disabled="!canDeleteFlow(form)">
									<el-tooltip
										:disabled="deleteFlowPermission"
										:content="$locale.baseText('permission.noPermissionFuncionality')"
										placement="top"
									>
										<div
											data-testid="button-delete-flow"
											class="projectDetailing__bodyCardsEnd"
											v-on:click="
												canDeleteFlow(form) &&
													((showAlert = true), (deleteData = { cell, index }))
											"
										>
											<div class="projectDetailing__bodyCardsEndIcon"></div>
											<div>{{ $locale.baseText('projectDetailing.del') }}</div>
										</div>
									</el-tooltip>
								</el-dropdown-item>
							</template>
						</MenuOptions>
					</div>
				</div>

				<div @click="canOpenFlow(projectActive) && redirectWorkflowExisting(cell.id, true)">
					<div class="projectDetailing__bodyCardsFrameRow">
						<div class="projectDetailing__bodyCardsFrameRowIcon1"></div>
						<div class="projectDetailing__bodyCardsFrameRowText">
							<div class="projectDetailing__bodyCardsFrameRowSubtitle">
								{{ $locale.baseText('projectDetailing.lastUpdate') }}
							</div>
							<div>{{ date(cell.dataUltimaAtualizacao) }}</div>
						</div>
					</div>
					<!-- <div class="projectDetailing__bodyCardsFrameRow">
						<div class="projectDetailing__bodyCardsFrameRowIcon2"></div>
						<div class="projectDetailing__bodyCardsFrameRowText">
							<div class="projectDetailing__bodyCardsFrameRowSubtitle">
								{{ $locale.baseText('projectDetailing.lastRun') }}
							</div>
							<div v-if="cell.dataUltimaExecucao && cell.resultadoUltimaExecucao">
								{{ date(cell.dataUltimaExecucao) }}
							</div>
							<div v-if="!cell.dataUltimaExecucao || !cell.resultadoUltimaExecucao">
								{{ $locale.baseText('projectDetailing.neverBeenExecuted') }}
							</div>
						</div>
					</div> -->
					<!-- <div class="projectDetailing__bodyCardsFrameRow">
						<div
							class="projectDetailing__bodyCardsFrameRowIcon3"
							v-if="cell.resultadoUltimaExecucao"
							v-bind:class="{
								projectDetailing__bodyCardsFrameRowIcon3__Success:
									cell.resultadoUltimaExecucao == 'Sucesso',
								projectDetailing__bodyCardsFrameRowIcon3__Alert:
									cell.resultadoUltimaExecucao == 'Alerta',
								projectDetailing__bodyCardsFrameRowIcon3__Failure:
									cell.resultadoUltimaExecucao == 'Falha',
								projectDetailing__bodyCardsFrameRowIcon3__NotStored:
									cell.resultadoUltimaExecucao == 'Não Armazenado',
							}"
						></div>
						<div
							v-if="!cell.resultadoUltimaExecucao"
							class="projectDetailing__bodyCardsFrameRowIcon3"
							v-bind:class="{
								projectDetailing__bodyCardsFrameRowIcon3__SemExecucao:
									!cell.resultadoUltimaExecucao,
							}"
						></div>
						<div class="projectDetailing__bodyCardsFrameRowText">
							<div class="projectDetailing__bodyCardsFrameRowSubtitle">
								{{ $locale.baseText('projectDetailing.flowResult') }}
							</div>
							<div v-if="cell.dataUltimaExecucao">
								<div v-if="cell.resultadoUltimaExecucao == 'Sucesso'">
									{{ $locale.baseText('projectDetailing.success') }}
								</div>
								<div v-if="cell.resultadoUltimaExecucao == 'Alerta'">
									{{ $locale.baseText('projectDetailing.alert') }}
								</div>

								<div v-if="cell.resultadoUltimaExecucao == 'Falha'">
									{{ $locale.baseText('projectDetailing.failure') }}
								</div>

								<div v-if="cell.resultadoUltimaExecucao == 'Não Armazenado'">
									{{ 'Não Armazenado' }}
								</div>
							</div>
							<div v-if="!cell.resultadoUltimaExecucao">
								{{ $locale.baseText('projectDetailing.neverBeenExecuted') }}
							</div>
						</div>
					</div> -->
					<div class="projectDetailing__bodyCardsFrameRow">
						<div
							class="projectDetailing__bodyCardsFrameRowIcon4"
							v-bind:class="{
								projectDetailing__bodyCardsFrameRowIcon4__Automatic: cell.automatico == true,
								projectDetailing__bodyCardsFrameRowIcon4__Manual: cell.automatico == false,
							}"
						></div>
						<div class="projectDetailing__bodyCardsFrameRowText">
							<div class="projectDetailing__bodyCardsFrameRowSubtitle">
								{{ $locale.baseText('projectDetailing.executionType') }}
							</div>
							<div v-if="cell.automatico == true">
								{{ $locale.baseText('projectDetailing.automatic') }}
							</div>
							<div v-if="cell.automatico == false">
								{{ $locale.baseText('projectDetailing.manual') }}
							</div>
						</div>
					</div>
					<div class="projectDetailing__bodyCardsFrameRow">
						<div class="projectDetailing__bodyCardsFrameRowIcon5"></div>
						<div class="projectDetailing__bodyCardsFrameRowText">
							<div class="projectDetailing__bodyCardsFrameRowSubtitle">
								{{ $locale.baseText('projectDetailing.attachedWorkflows') }}
							</div>
							<div>
								{{
									cell.quantidadeDeFluxosAnexados > 0
										? cell.quantidadeDeFluxosAnexados + ' Anexo(s)'
										: 'Nenhum'
								}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-else class="projectDetailing__bodyTable">
			<Table
				@endScroll="paginations()"
				:scrollInfinite="scrollInfinite"
				:isLoading="isLoadingTable"
				:textIfEmpty="emptyListWorkflow"
			>
				<template v-slot:headerColumns>
					<tr class="table__tableTheadTr">
						<th class="table__tableTheadTrTh">
							<div class="table__tableTheadTrThTitle">
								<div class="table__tableTheadTrThTitleText">
									<i class="select-icon square" @click="emitSelectAll()" :class="{ selected: allWorkflowsSelected, partialSelected: partialWorkflowsSelected }"></i>
									{{ $locale.baseText('projectDetailing.table.workflow') }}
								</div>
							</div>
						</th>
						<th class="table__tableTheadTrTh">
							<div class="table__tableTheadTrThTitle">
								<div class="table__tableTheadTrThTitleText">
									{{ $locale.baseText('projectDetailing.table.attachedWorkflow') }}
								</div>
							</div>
						</th>
						<th class="table__tableTheadTrTh">
							<div class="table__tableTheadTrThTitle">
								<div class="table__tableTheadTrThTitleText">
									{{ $locale.baseText('projectDetailing.lastUpdate') }}
								</div>
							</div>
						</th>
						<!-- <th class="table__tableTheadTrTh">
							<div class="table__tableTheadTrThTitle">
								<div class="table__tableTheadTrThTitleText">
									{{ $locale.baseText('projectDetailing.lastRun') }}
								</div>
							</div>
						</th>
						<th width="160px" class="table__tableTheadTrTh">
							<div class="table__tableTheadTrThTitle">
								<div class="table__tableTheadTrThTitleText">
									{{ $locale.baseText('projectDetailing.table.results') }}
								</div>
							</div>
						</th> -->
						<th width="129px" class="table__tableTheadTrTh">
							<div class="table__tableTheadTrThTitle">
								<div class="table__tableTheadTrThTitleText">
									{{ $locale.baseText('projectDetailing.table.executions') }}
								</div>
							</div>
						</th>

						<th
							width="72px"
							class="table__tableTheadTrTh"
							v-bind:class="{ table__tableTheadTrThLastColumn: false }"
						>
							<div class="table__tableTheadTrThTitle">
								<div class="table__tableTheadTrThTitleText">
									{{ $locale.baseText('projectDetailing.actions') }}
								</div>
							</div>
						</th>
					</tr>
				</template>
				<template v-slot:columns>
					<tr v-for="(column, index) in workflowData" :key="column.id">
						<td
							class="projectDetailing__bodyTableColumn_1"
							v-bind:class="{ projectDetailing__bodyTableColumn_1Inactive: !column.ativo }"
						>
							<div
								class="titleColumn_1"
								@click="canOpenFlow(projectActive) && redirectWorkflowExisting(column.id, true)"
							>
								<div class="select-icon square" style="min-width: 18px" @click="toggleSelectWorkflow(column, true)" :class="{ selected: column?.selectedWorkflow, onlyBlocked: !column.ativo }"></div>
								{{ column.nome }}
							</div>

							<!-- <span class="tooltipAliare">
								<p v-if="canOpenFlow(projectActive)" class="info-title">{{ 'Acessar Fluxo' }}</p>
								<p class="info-organization">
									{{
										canOpenFlow(projectActive)
											? column.nome
											: $locale.baseText('permission.noPermissionFuncionality')
									}}
								</p>
							</span> -->
						</td>
						<td>
							{{
								column.quantidadeDeFluxosAnexados > 0
									? column.quantidadeDeFluxosAnexados + ' Anexo(s)'
									: 'Nenhum'
							}}
						</td>
						<td>{{ formatDate(column.dataUltimaAtualizacao) }}</td>
						<!-- <td>
							<div v-if="column.resultadoUltimaExecucao">
								{{ formatDate(column.dataUltimaExecucao) }}
							</div>
							<div v-else>{{ $locale.baseText('projectDetailing.neverBeenExecuted') }}</div>
						</td>
						<td width="160px">
							<div
								v-if="column.resultadoUltimaExecucao"
								class="projectDetailing__bodyTableColumn_Frame"
								v-bind:class="{
									projectDetailing__bodyTableColumn_4_Success:
										column.resultadoUltimaExecucao == 'Sucesso',
									projectDetailing__bodyTableColumn_4_Alert:
										column.resultadoUltimaExecucao == 'Alerta',
									projectDetailing__bodyTableColumn_4_Failure:
										column.resultadoUltimaExecucao == 'Falha',
									projectDetailing__bodyTableColumn_4_NotStored:
										column.resultadoUltimaExecucao == 'Não Armazenado',
								}"
							>
								<div v-if="column.resultadoUltimaExecucao == 'Sucesso'">
									{{ $locale.baseText('projectDetailing.success') }}
								</div>

								<div v-else-if="column.resultadoUltimaExecucao == 'Alerta'">
									{{ $locale.baseText('projectDetailing.alert') }}
								</div>

								<div v-else-if="column.resultadoUltimaExecucao == 'Falha'">
									{{ $locale.baseText('projectDetailing.failure') }}
								</div>
								<div v-else-if="column.resultadoUltimaExecucao == 'Não Armazenado'">
									{{ $locale.baseText('Não Armazenado') }}
								</div>
							</div>
							<div
								v-if="!column.resultadoUltimaExecucao"
								class="projectDetailing__bodyTableColumn_Frame"
							>
								{{ $locale.baseText('projectDetailing.neverBeenExecuted') }}
							</div>
						</td> -->
						<td width="129px">
							<div
								class="projectDetailing__bodyTableColumn_Frame"
								v-bind:class="{
									projectDetailing__bodyTableColumn_5_Automatic: column.automatico == true,
									projectDetailing__bodyTableColumn_5_Manual: column.automatico == false,
								}"
							>
								<div v-if="column.automatico == true">
									{{ $locale.baseText('projectDetailing.automatic') }}
								</div>
								<div v-if="column.automatico == false">
									{{ $locale.baseText('projectDetailing.manual') }}
								</div>
							</div>
						</td>

						<td width="72px">
							<div class="projectDetailing__bodyTableColumn_BtnCenter">
								<el-tooltip popper-class="custom-tooltip" :visible-arrow="false" :offset="50">
									<el-button
										class="projectDetailing__bodyTableColumn_Btn"
										@click="v_show(column.id)"
									></el-button>
									<div slot="content">
										<MenuOptions :Right="'5px'" :Top="'23px'" v-show="showFlow == column.id">
											<template v-slot:top>
												<div class="projectDetailing__bodyCardsOptionsTop">
													<el-dropdown-item :disabled="!canEditFlowInProjectDetail(column, form)">
														<el-tooltip
															:disabled="editFlowPermission"
															:content="$locale.baseText('permission.noPermissionFuncionality')"
															placement="top"
														>
															<div
																data-testid="button-open-flow"
																class="projectDetailing__bodyCardsOptions"
																@click="
																	canEditFlowInProjectDetail(column, form) &&
																		redirectWorkflowExisting(column.id)
																"
															>
																<div
																	class="projectDetailing__bodyCardsOptions_Icon projectDetailing__bodyCardsOptions_Icon_0"
																></div>
																<div>{{ $locale.baseText('Editar Fluxo') }}</div>
															</div>
														</el-tooltip>
													</el-dropdown-item>

													<el-dropdown-item :disabled="!canRenameFlow(column, form)">
														<el-tooltip
															:disabled="editFlowPermission"
															:content="$locale.baseText('permission.noPermissionFuncionality')"
															placement="top"
														>
															<div
																data-testid="button-open-flow"
																class="projectDetailing__bodyCardsOptions"
																@click="
																	canRenameFlow(column, form) &&
																		showModals(column.id, 'renameWorkflow')
																"
															>
																<div
																	class="projectDetailing__bodyCardsOptions_Icon projectDetailing__bodyCardsOptions_Icon_1"
																></div>
																<div>{{ $locale.baseText('projectDetailing.renameFlow') }}</div>
															</div>
														</el-tooltip>
													</el-dropdown-item>

													<el-dropdown-item :disabled="!canDuplicateFlow(column, form)">
														<el-tooltip
															:disabled="duplicateFlowPermission"
															:content="$locale.baseText('permission.noPermissionFuncionality')"
															placement="top"
														>
															<div
																data-testid="button-open-modal-duplicate"
																class="projectDetailing__bodyCardsOptions"
																@click="
																	canDuplicateFlow(column, form) &&
																		openModalDuplicateWorkflow(
																			column.id,
																			organization?.length > 1 ? true : false,
																		)
																"
															>
																<div
																	class="projectDetailing__bodyCardsOptions_Icon projectDetailing__bodyCardsOptions_Icon_2"
																></div>
																<div>{{ $locale.baseText('projectDetailing.tect_2') }}</div>
															</div>
														</el-tooltip>
													</el-dropdown-item>

													<el-dropdown-item :disabled="!canAccessVersion(column, projectActive)">
														<el-tooltip
															:disabled="editFlowPermission || openFlowPermission"
															:content="$locale.baseText('permission.noPermissionFuncionality')"
															placement="top"
														>
															<div
																class="projectDetailing__bodyCardsOptions"
																@click="
																	canAccessVersion(column, projectActive) &&
																		showModals(column.id, 'historyVersion')
																"
															>
																<div
																	class="s projectDetailing__bodyCardsOptions_Icon projectDetailing__bodyCardsOptions_Icon_4"
																></div>
																<div>{{ $locale.baseText('projectDetailing.tect_4') }}</div>
															</div>
														</el-tooltip>
													</el-dropdown-item>

													<!-- <div class="projectDetailing__bodyCardsOptions">
																	<div
																		class="
																			projectDetailing__bodyCardsOptions_Icon
																			projectDetailing__bodyCardsOptions_Icon_3
																		"
																	></div>
																	<div>{{ $locale.baseText('projectDetailing.tect_3') }}</div>
																</div> -->

													<el-dropdown-item
														:disabled="!canAccessExecutionFlow(column, projectActive)"
													>
														<el-tooltip
															:disabled="editFlowPermission || openFlowPermission"
															:content="$locale.baseText('permission.noPermissionFuncionality')"
															placement="top"
														>
															<div
																class="projectDetailing__bodyCardsOptions"
																@click="
																	canAccessExecutionFlow(column, projectActive) &&
																		showModals(column.id, 'executions')
																"
															>
																<div
																	class="projectDetailing__bodyCardsOptions_Icon projectDetailing__bodyCardsOptions_Icon_5"
																></div>
																<div>{{ $locale.baseText('projectDetailing.tect_5') }}</div>
															</div>
														</el-tooltip>
													</el-dropdown-item>

													<el-dropdown-item
														:disabled="!canAccessAttachmentFlows(column, projectActive)"
													>
														<el-tooltip
															:disabled="editFlowPermission || openFlowPermission"
															:content="$locale.baseText('permission.noPermissionFuncionality')"
															placement="top"
														>
															<div
																class="projectDetailing__bodyCardsOptions"
																@click="
																	canAccessAttachmentFlows(column, projectActive) &&
																		showModals(column.id, 'attachedWorkflows')
																"
															>
																<div
																	class="projectDetailing__bodyCardsOptions_Icon projectDetailing__bodyCardsOptions_Icon_8"
																></div>
																<div>{{ $locale.baseText('projectDetailing.tect_8') }}</div>
															</div>
														</el-tooltip>
													</el-dropdown-item>

													<!-- <el-dropdown-item :disabled="!canViewShareFlow(column, projectActive)">
																	<el-tooltip :disabled="canViewShareFlow(column, projectActive)" placement="top">
																		<div
																			class="projectDetailing__bodyCardsOptions"
																			@click="openShareModal({...column, nomeProjeto: form.nome}, 'Workflow', !canShareFlow(column, projectActive))"
																		>
																			<div
																				class="projectDetailing__bodyCardsOptions_Icon projectDetailing__bodyCardsOptions_Icon_9"
																			></div>
																			<div>{{ $locale.baseText('project.share') }}</div>
																		</div>
																	</el-tooltip>
																</el-dropdown-item> -->

													<el-dropdown-item
														:disabled="!canManageVariablesFlow(column, projectActive)"
													>
														<el-tooltip
															:disabled="editFlowPermission || openFlowPermission"
															:content="$locale.baseText('permission.noPermissionFuncionality')"
															placement="top"
														>
															<div
																class="projectDetailing__bodyCardsOptions"
																@click="
																	canManageVariablesFlow(column, projectActive) &&
																		showModals(column.id, 'manageVariable')
																"
															>
																<div
																	class="projectDetailing__bodyCardsOptions_Icon projectDetailing__bodyCardsOptions_Icon_6"
																></div>
																<div>{{ $locale.baseText('projectDetailing.tect_6') }}</div>
															</div>
														</el-tooltip>
													</el-dropdown-item>
												</div>
												<div class="division"></div>
											</template>
											<template v-slot:middle>
												<div class="projectDetailing__bodyCardsMiddle">
													<el-dropdown-item :disabled="!canActiveInactiveFlow(form)">
														<el-tooltip	:disabled="editFlowPermission" :form="$locale.baseText('login.noPermission')"
															placement="top"
														>
															<div class="options-middle">
																<div
																	class="switch_box box_4"
																	@click="canActiveInactiveFlow(form) && changeActive(!column.ativo, column)"
																	:class="{'switch_box--disableButton': !canActiveInactiveFlow(form)}"
																>
																	<div class="input_wrapper">
																		<input type="checkbox" v-model="column.ativo" class="switch_4" />
																		<div class="content-switch is_checked">Ativo</div>
																		<div class="content-switch is_unchecked">Inativo</div>
																	</div>
																</div>
															</div>
														</el-tooltip>
													</el-dropdown-item>

													<el-dropdown-item	:disabled="!canChangeAutomaticManualFlow(column, form) || !column.ativo">
														<el-tooltip	:disabled="editFlowPermission" :content="$locale.baseText('permission.noPermissionFuncionality')"
															placement="top"
														>
															<div class="options-middle">
																<div
																	class="switch_box box_4"
																	@click="canChangeAutomaticManualFlow(column, form) && changeExecution(!column.automatico, column)"
																	:class="{'switch_box--disableButton': !canChangeAutomaticManualFlow(column, form)}"
																>
																	<div class="input_wrapper">
																		<input type="checkbox" v-model="column.automatico" class="switch_4" />
																		<div class="content-switch is_checked isAutomatic">Automática</div>
																		<div class="content-switch is_unchecked">Manual</div>
																	</div>
																</div>
															</div>
														</el-tooltip>
													</el-dropdown-item>
												</div>
											</template>
											<template v-slot:end>
												<el-dropdown-item :disabled="!canDeleteFlow(form)">
													<el-tooltip
														:disabled="deleteFlowPermission"
														:content="$locale.baseText('permission.noPermissionFuncionality')"
														placement="top"
													>
														<div
															data-testid="button-delete-flow"
															class="projectDetailing__bodyCardsEnd"
															v-on:click="
																canDeleteFlow(form) &&
																	((showAlert = true),
																	(deleteData = { column, index }))
															"
														>
															<div class="projectDetailing__bodyCardsEndIcon"></div>
															<div>{{ $locale.baseText('projectDetailing.del') }}</div>
														</div>
													</el-tooltip>
												</el-dropdown-item>
											</template>
										</MenuOptions>
									</div>
								</el-tooltip>
							</div>
						</td>
					</tr>
				</template>
			</Table>
		</div>

		<AlertNotificationModal
			v-if="showAlert"
		>
			<template v-slot:icon>
				<i class="equivalenceCreateSuccessIcon"></i>
			</template>

			<template v-slot:title>
				{{ 'Exclusão de Fluxo' }}
			</template>
			<template v-slot:firstText>
				<p> {{ $locale.baseText('projectDetailing.modalDelete.modal__DeleteBody') }}</p>
			</template>

			<template v-slot:footer>
				<ModalButtonSuccess
					:titleButton="'Sim'"
					width="300"
					:loadingAction="isLoadingAction"
					@click="deleteFlow(deleteData)"
					alwaysActive
					plusIcon
				/>
				<ModalButtonCancel
					width="300"
					:titleButton="'Não'"
					@click="showAlert = false"
				/>
			</template>
		</AlertNotificationModal>
	</div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';
import WebStorage from '@/common/WebStorage';
import { restApi } from '@/components/mixins/restApi';
import { setupHeaderAliareTenant } from '@/config/axiosConfig';
import { workflowPermission } from '@/components/mixins/permissions/workflow';
import { HISTORY_VERSION_MODAL_KEY,	EXECUTIONS_LIST_MODAL_KEY, RENAME_WORKFLOW_MODAL_KEY,	ATTACHED_WORKFLOW_MODAL_KEY,} from '@/constants';
import Vue from 'vue';

import Table from '@/components/Table.vue';
import MenuOptions from '@/components/MenuOptions.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ActiveDeactivateButton from '@/components/ActiveDeactivateButton.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';

export default mixins(restApi, workflowPermission).extend({
	components: {
		Table,
		MenuOptions,
		ModalButtonCancel,
		ModalButtonSuccess,
		ActiveDeactivateButton,
		AlertNotificationModal,
	},

	// @ts-ignore
	emits: ['reload', 'pagination'],
	props: {
		form: Object,
		workflowData: Array,
		viewDataCard: Boolean,
		projectActive: Boolean,
	},

	data() {
		return {
			pageSize: 10,
			showFlow: '',
			projectId: '',
			deleteData: {},
			active: 'activeDeactivateButton.active',
			tenantId: WebStorage.getItem("tenantId"),
			inactive: 'activeDeactivateButton.inactive',
			organization: JSON.parse(WebStorage.getItem('cardOrganization')),
			selectMode: false,
			showAlert: false,
			multiAccount: false,
			isLoadingAction: false,
			scrollInfinite: true,
			renderComponent: true,
			isLoadingTable: false,
			notResultFilter: false,
			arrayWorkflowsSelected: [],

		};
	},
	mounted() {
		this.$root.$on('switchSelectMode', () => {
			this.selectMode = !this.selectMode;
		});
		this.$root.$on('cancelSwitchMode', () => {
			this.workflowData.forEach((form) => {
				form.selectedWorkflow = false;
			});
			this.arrayWorkflowsSelected = [];
			this.selectMode = false;
		});
		this.getInfos();
	},
	computed: {
		partialWorkflowsSelected() {
			return this.workflowsAbleToSelect != this.arrayWorkflowsSelected.length && this.arrayWorkflowsSelected.length > 0;
		},
		allWorkflowsSelected() {
			return this.workflowsAbleToSelect == this.arrayWorkflowsSelected.length;
		},
		workflowsAbleToSelect() {
			return this.workflowData.filter(workflow => this.form.permissao == 'Editar').length;
		},
		workspace() {
			return this.$store.getters.workspace;
		},
		emptyListWorkflow() {
			return this.notResultFilter ? this.$locale.baseText('projectDetailing.emptyListFilter') : this.$locale.baseText('projectDetailing.emptyList')
		},
	},
	methods: {
		...mapActions('project', ['patchActive', 'delete']),
		...mapActions('workflows', ['patchWorkflowsWorkflowActive',	'patchWorkflowsWorkflowAutomatic']),
		emitSelectAll() {
			//this.isLoading = true;
			if (this.allWorkflowsSelected) {
				this.workflowData.forEach((form) => {
					form.selectedWorkflow = false;
				});
				this.arrayWorkflowsSelected = [];
				//this.isLoading = false;
				this.$root.$emit('deselectAll');
			} else {
				this.$emit('selectAllWorkflows');
			}
		},
		async forceRerender() {
			this.renderComponent = false;

			await Vue.nextTick();

			this.renderComponent = true;
		},
		toggleSelectWorkflow(form, emitSwitch) {

			this.$root.$emit('addOrRemoveItemSelected', JSON.parse(JSON.stringify({
				...form,
			})));
			form.selectedWorkflow = !form?.selectedWorkflow;

			if (form.selectedWorkflow) {
				this.arrayWorkflowsSelected.push(form);
			} else {
				let index = this.arrayWorkflowsSelected.map(items => {
					return items.id;
				}).indexOf(form.id);

				this.arrayWorkflowsSelected.splice(index, 1);
			}
			if (emitSwitch && !this.selectMode) {
				this.$root.$emit('switchSelectMode', {active: JSON.parse(JSON.stringify(form.selectedWorkflow)), object: 'Workflow'});
			}
			this.forceRerender();
		},
		getInfos() {
			this.projectId = this.form.id;

			if(this.workflowData?.length < 10) {
				this.scrollInfinite = false;
			}
		},

		date(data) {
			let testDateUtc = moment.utc(data);
			let localDate = moment(testDateUtc).local().format('DD/MM/YYYY [às] HH:mm');
			return localDate;
		},
		formatDate(date) {
			let dateUtc = moment.utc(date);
			let localDate = moment(dateUtc).local().format('DD/MM/YYYY [às] HH[h]:mm[min]');
			return localDate;
		},

		redirectWorkflowExisting(workflowId, viewMode) {
			this.$router.push({
				name: 'NodeViewExisting',
				params: { name: workflowId, mode: !!viewMode ? 'Visualizar' : 'Editar'},
			});
		},
		v_show(res) {
			if (this.showFlow === res) {
				this.showFlow = '';
			} else {
				this.showFlow = res;
			}
		},
		showModals(workflowId, key) {
			if (key === 'executions') {
				this.$store.commit('setWorkflowId', workflowId);
				this.$store.commit('setOpenRouteName', 'viaDetailProject');
				this.$store.dispatch('ui/openModal', EXECUTIONS_LIST_MODAL_KEY);

			} else if (key === 'historyVersion') {
				this.$store.commit('setWorkflowDataId', workflowId);
				this.$store.dispatch('ui/openModal', HISTORY_VERSION_MODAL_KEY);

			} else if (key === 'manageVariable') {
				this.$store.dispatch('ui/openManageVariableModal', {	data: { workflowId }});

			} else if (key === 'renameWorkflow') {
				this.$store.commit('setWorkflowId', workflowId);
				this.$store.dispatch('ui/openModal', RENAME_WORKFLOW_MODAL_KEY);
			} else if (key === 'attachedWorkflows') {
				this.$store.commit('setWorkflowDataId', workflowId);
				this.$store.dispatch('ui/openModal', ATTACHED_WORKFLOW_MODAL_KEY);
			}
		},
		openModalDuplicateWorkflow(workflowId, multi) {
			const openMode = !multi ? 'oneAccount' : 'multiAccount';
			this.$store.dispatch('ui/openDuplicateWorkflowModal', {	data: { workflowId }, mode: openMode });
		},

		changeActive(active, cell) {
			if (active === cell.ativo) return;

			setupHeaderAliareTenant(this.tenantId);
			const status = { workspaceId: this.workspace.id, id: cell.id, ativo: active };
			const ProjectName = this.form.nome;
			const FlowName = cell.nome;

			this.patchWorkflowsWorkflowActive(status).then((response) => {
				const successMessageKey = response.data.ativo ? 'projectDetailing.sucessActive' : 'projectDetailing.sucessInactive';

				this.$store.commit('activeAlert', {
					message: this.$locale.baseText(successMessageKey, { interpolate: { FlowName, ProjectName } }),
				});

				cell.ativo = response.data.ativo;
				this.$root.$emit('closeSelectItemsComponent');
				this.$emit("reload");
			})
			.catch((error) => {
				const ErrorStatus = error.response?.data?.notifications;
				const errorMessageKey = 'projectDetailing.errorActiveOrInactive';

				this.$store.commit('activeAlert', {
					message: this.$locale.baseText(errorMessageKey, { interpolate: { FlowName, ErrorStatus } }),
					status: 'error',
				});
				this.$emit("reload");
			});
		},
		changeExecution(automatic, cell) {
			if (automatic === cell.automatico) return;

			setupHeaderAliareTenant(this.tenantId);
			const status = { workspaceId: this.workspace.id, id: cell.id, automatico: automatic };
			const ProjectName = this.form.nome;
			const FlowName = cell.nome;

			this.patchWorkflowsWorkflowAutomatic(status).then((response) => {
				const successMessage = response.data.automatico
				? this.$locale.baseText('projectDetailing.sucessAutomatic', { interpolate: { FlowName, ProjectName } })
				: this.$locale.baseText('projectDetailing.sucessManual', { interpolate: { FlowName, ProjectName } });

				this.$store.commit('activeAlert', {
					message: successMessage,
				});
				this.$emit("reload");
			})
			.catch((error) => {
				const ErrorExecutions = error.response?.data?.notifications;
				this.$store.commit('activeAlert', {
					message:  this.$locale.baseText('projectDetailing.errorExecution2', { interpolate: {ErrorExecutions} }),
					status: 'error',
				});
				this.$emit("reload");
			});
		},
		async deleteFlow(deleteData) {
			this.isLoadingAction = true;

			try {
				let deleteId = deleteData.cell ? deleteData.cell.id : deleteData.column.id;
				await this.restApi().deleteWorkflow(this.workspace.id, deleteId);

				const deleteFlowName = deleteData.cell ? deleteData.cell.nome : deleteData.column.nome;
				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('projectDetailing.modalDelete.flowDeleteSuccess', {
						interpolate: { deleteFlowName },
					}),
				});
				this.showAlert = false;
				this.$emit("reload");

			} catch (error) {
				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('projectDetailing.modalDelete.deleteError'),
					status: 'error',
				});
				console.error(error);
			}

			this.isLoadingAction = false;
			this.showModalDelete = false;
		},
		async paginations() {
			if (this.workflowData?.length % this.pageSize === 0) {
				this.$emit("pagination");
				return;
			}

			this.scrollInfinite = false;
			return false;
		},
	},
	watch: {
		workflowData(newValue, oldValue) {
			if (!newValue) return;
			if(newValue[0].selectedWorkflow) {
				this.workflowData.forEach((workflow) => {
					if (!this.canEditFlowInProjectDetail(workflow, this.form)) {
						workflow.selectedWorkflow = false;
					}
				})
				const workflowsSelected = this.workflowData.filter(workflow => workflow.selectedWorkflow);
				this.arrayWorkflowsSelected = JSON.parse(JSON.stringify(workflowsSelected));
				this.$root.$emit('selectAll', JSON.parse(JSON.stringify(workflowsSelected)), {active: true, object: 'Workflow'});
			}
		},
	},
});
</script>

<style lang="scss" scoped>
.cardFlow__selectAll {
	display: flex;
	color: rgba(255, 255, 255, 0.88);
	width: 100%;
	align-items: center;
	font-size: 12px;
	margin: 8px 0;
}
.projectDetailing__bodyCard {
		padding: 0px 16px;
		display: flex;
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		row-gap: 17px;
		flex-wrap: wrap;
		-moz-column-gap: 17px;
		column-gap: 17px;
		justify-content: flex-start;
		margin-top: 1.66%;

		.projectDetailing__bodyCardsFrame {
			width: 100%;
			padding-bottom: 16px;
			background-color: #273a5b;
			border: 1px solid #46587c;
			box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.32);
			border-radius: 4px;
			position: relative;

			&:hover {
				cursor: pointer;

				.tooltipAliare {
					display: flex;
					flex-direction: column;
					z-index: 9;
					width: 322px;
					font-size: 14px;

					.info-title {
						font-weight: 500;
						margin: 0;
					}

					.info-organization {
						font-weight: 400;
						color: #FFFFFF8F;
					}
				}

			}

			.projectDetailing__bodyCardsFrameHeader {
				background-color: #2c3d5b;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: 1px solid #46587c;
				padding: 10px 0px;

				.projectDetailing__bodyCardsFrameHeaderAux {
					display: flex;
					align-items: center;
				}

				.projectDetailing__bodyCardsFrameHeaderIcon {
					min-width: 20px;
					height: 18px;
					background-image: url('../assets/flowIcon.svg');
					margin: 0px 22px;
					background-repeat: no-repeat;
					background-position: center;
					background-size: contain;
				}

				.projectDetailing__bodyCardsFrameHeaderTitle {
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
				}

				.projectDetailing__bodyCardsFrameHeaderTitle p {
					overflow: hidden;
					text-overflow: ellipsis;
					line-height: 16px;
					overflow-wrap: anywhere;
				}

				.projectDetailing__bodyCardsFrameHeaderOptions {
					border-radius: 3px;
					width: 28px;
					height: 28px;
					background-image: url('../assets/threeGrayDots.svg');
					background-repeat: no-repeat;
					background-position: center;
					margin: 0 4px 0 8px;
					cursor: pointer;
				}

				.projectDetailing__bodyCardsFrameHeaderOptions:hover {
					background-color: rgba(255, 255, 255, 0.08);
				}
			}

			.projectDetailing__bodyCardsFrameHeaderInactive {
				background-color: #3b424e !important;
				border-bottom: 1px solid #3b424e;

				.projectDetailing__bodyCardsFrameHeaderIcon {
					width: 20px;
					height: 18px;
					background-image: url('../assets/cardIconFolderInactive.svg');
					background-repeat: no-repeat;
					background-position: center;
					margin: 0px 22px;
				}
			}

			.projectDetailing__bodyCardsFrameRow {
				display: flex;
				align-items: center;
				padding: 16px 16px 0px 16px;

				.projectDetailing__bodyCardsFrameRowIcon1 {
					background-image: url('../assets/calendarIcon.svg');
					background-repeat: no-repeat;
					background-position: center;
					background-color: rgba(0, 163, 255, 0.08);
					width: 32px;
					height: 32px;
					border-radius: 3px;
					margin-right: 8px;
				}

				.projectDetailing__bodyCardsFrameRowSubtitle {
					font-weight: 400;
					font-size: 13px;
					line-height: 15px;
					color: rgba(255, 255, 255, 0.72);
				}

				.projectDetailing__bodyCardsFrameRowIcon2 {
					background-image: url('../assets/radioPlayIconPurple.svg');
					background-repeat: no-repeat;
					background-position: center;
					background-color: rgba(252, 96, 255, 0.08);
					width: 32px;
					height: 32px;
					border-radius: 3px;
					margin-right: 8px;
				}

				.projectDetailing__bodyCardsFrameRowIcon3 {
					background-repeat: no-repeat;
					background-position: center;
					width: 32px;
					height: 32px;
					border-radius: 3px;
					margin-right: 8px;
				}

				.projectDetailing__bodyCardsFrameRowIcon5 {
					background-image: url('../assets/blueAttachedWorkflow.svg') !important;
					background-repeat: no-repeat;
					background-position: center;
					width: 32px;
					height: 32px;
					border-radius: 3px;
					margin-right: 8px;
				}

				.projectDetailing__bodyCardsFrameRowIcon3__SemExecucao {
					background-repeat: no-repeat !important;
					background-position: center !important;
					background-image: url('../assets/blueCircle.svg') !important;
					background: rgba(172, 215, 255, 0.08);
				}

				.projectDetailing__bodyCardsFrameRowIcon3__Success {
					background-image: url('../assets/radioCheckIconGreen.svg');
					background-color: rgba(89, 214, 76, 0.08);
				}

				.projectDetailing__bodyCardsFrameRowIcon3__Alert {
					background-image: url('../assets/cardIconAlert.svg');
				}

				.projectDetailing__bodyCardsFrameRowIcon3__Failure {
					background-image: url('../assets/cardIconError.svg');
				}

				.projectDetailing__bodyCardsFrameRowIcon3__NotStored {
					background-image: url('../assets/iconNotStored.svg');
				}

				.projectDetailing__bodyCardsFrameRowIcon4 {
					background-repeat: no-repeat;
					background-position: center;
					background-color: rgba(172, 215, 255, 0.08);
					width: 32px;
					height: 32px;
					border-radius: 3px;
					margin-right: 8px;
				}

				.projectDetailing__bodyCardsFrameRowIcon4__Automatic {
					background-image: url('../assets/gearIcon.svg') !important;
				}

				.projectDetailing__bodyCardsFrameRowIcon4__Manual {
					background-image: url('../assets/manualIcon.svg');
				}
			}
		}

		.projectDetailing__bodyCardsFrameInactive {
			background-color: #28303d !important;
			border: 1px solid #3b424e !important;
			width: 320px;
		}

		.projectDetailing__bodyCardsFrameActive {
			background-color: #273a5b !important;
			border: 1px solid #46587c !important;
			width: 320px;
		}
}
.projectDetailing__bodyTable {
		padding: 0px 16px;

		.projectDetailing__bodyTableColumn_1 {
			font-weight: 500;
			font-size: 12px;
			line-height: 16px;
			color: #59d64c;

			.titleColumn_1 {
				display: flex;
				align-items: center;
				cursor: pointer;
			}

			&:hover {
				position: relative;

				.tooltipAliare {
					display: flex;
					flex-direction: column;
					z-index: 9;
					width: 220px;
					font-size: 14px;

					.info-title {
						font-weight: 500;
						margin: 0;
					}

					.info-organization {
						font-weight: 400;
						color: #FFFFFF8F;
					}
				}
			}
		}

		.projectDetailing__bodyTableColumn_1Inactive {
			color: rgba(255, 255, 255, 0.56) !important;
		}

		.projectDetailing__bodyTableColumn_Frame {
			text-align: center;
			border: 1px solid #34476a;
			border-radius: 100px;
			padding: 4px 0px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
		}

		.projectDetailing__bodyTableColumn_4_Success {
			color: #00a3ff;
		}

		.projectDetailing__bodyTableColumn_4_Alert {
			color: #ffa53b;
		}

		.projectDetailing__bodyTableColumn_4_NotStored {
			color: #d57e0d;
		}

		.projectDetailing__bodyTableColumn_4_Failure {
			color: #ff495f;
		}

		.projectDetailing__bodyTableColumn_5_Automatic {
			color: #acd7ff;
		}

		.projectDetailing__bodyTableColumn_5_Manual {
			color: rgba(252, 96, 255, 0.64);
		}

		.projectDetailing__bodyTableColumn_BtnCenter {
			display: flex;
			align-items: center;
			justify-content: center;

			.projectDetailing__bodyTableColumn_Btn {
				width: 32px;
				height: 32px;
				border: 1px solid #4a638f;
				box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24);
				border-radius: 4px;
				background-image: url('../assets/threeGrayDots.svg');
				background-repeat: no-repeat;
				background-position: center;
				cursor: pointer;
			}

			.projectDetailing__bodyTableColumn_Btn:hover {
				background-color: rgba(255, 255, 255, 0.08);
				border: 1px solid #60769d;
			}
		}
}
.isAutomatic {
	left: 13px !important;
}
.options-middle {
  &:deep {
    .switch_box {
      cursor: pointer;
			height: 37px;

      .input_wrapper {
        pointer-events: none;
        width: 173px;
        height: 48px;
        font-size: 12px;

        .is_checked {
          left: 26px;
        }

        .is_unchecked {
          right: 21px;
        }

        input {
          width: 173px;
          height: 31px;

          &:after {
            width: 83px;
            height: 25px;
            left: calc(100% - 85px);
            background: #FF495F;
          }

          &:checked:after {
            width: 84px;
            height: 25px;
            left: 3px;
            background: #00C3DD;
          }
        }
      }

      &--disableButton {
        cursor: not-allowed;

        .input_wrapper {
          input {
            color: #bbb;
            background: #141722;

            &:after {
              background: #696969;
            }

            &:checked:after {
              background: #696969;
            }
          }
        }
      }
    }
  }
}

.projectDetailing__bodyCardsOptionsTop {
	padding: 3px 1px;
}
.projectDetailing__bodyCardsOptions {
	display: flex;
	padding: 6px 16px;
	align-items: center;
	width: 100%;
	border-radius: 3px;
	cursor: pointer;
	border: 1px solid transparent;
	color: #00c3dd;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;

	.projectDetailing__bodyCardsOptions_Icon {
		background-position: center;
		background-repeat: no-repeat;
		margin-right: 17px;
		width: 18px;
		height: 18px;
	}

	.projectDetailing__bodyCardsOptions_Icon_0 {
		background-image: url('../assets/pen-blue.svg');
	}

	.projectDetailing__bodyCardsOptions_Icon_1 {
		background-image: url('../assets/renameIcon.svg');
	}

	.projectDetailing__bodyCardsOptions_Icon_2 {
		background-image: url('../assets/duplicateFlowIcon.svg');
	}

	.projectDetailing__bodyCardsOptions_Icon_3 {
		background-image: url('../assets/bigBlueCurvedArrow.svg');
	}

	.projectDetailing__bodyCardsOptions_Icon_4 {
		background-image: url('../assets/blueClock.svg');
	}

	.projectDetailing__bodyCardsOptions_Icon_5 {
		background-image: url('../assets/accessExecutionsIcon.svg');
	}

	.projectDetailing__bodyCardsOptions_Icon_6 {
		background-image: url('../assets/manageVariablesIcon.svg');
	}

	.projectDetailing__bodyCardsOptions_Icon_7 {
		background-image: url('../assets/clipboardIcon.svg');
	}

	.projectDetailing__bodyCardsOptions_Icon_8 {
		background-image: url('../assets/linkIcon.svg');
	}
	.projectDetailing__bodyCardsOptions_Icon_9 {
		background-size: 15px;
		background-image: url('../assets/blueShareIcon.svg');
	}

	&:hover {
		border: 1px solid #00c3dd;
	}
}
.projectDetailing__bodyCardsMiddle {
	padding: 0px 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-bottom: 1px solid #46587c;
}
.projectDetailing__bodyCardsEnd {
	display: flex;
	align-items: center;
	padding: 6px 17px;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #ff495f;
	margin: 3px 1px;
	border: 1px solid transparent;
	cursor: pointer;

	.projectDetailing__bodyCardsEndIcon {
		background-repeat: no-repeat;
		background-position: center;
		width: 18px;
		height: 18px;
		background-image: url('../assets/delete.svg');
		margin-right: 17px;
	}
}

:deep {
	.menuOptions__background {
		z-index:14;
		width: 205px;
	}

	.el-dropdown-menu__item.is-disabled {
    pointer-events: auto;
	}

	.el-dropdown-menu__item.is-disabled .projectDetailing__bodyCardsEnd {
		color: #bbbbbb;
	}

	.el-dropdown-menu__item.is-disabled .projectDetailing__bodyCardsEnd .projectDetailing__bodyCardsEndIcon {
		background-image: url('./../assets/cardIconDeleteGrey.svg');
	}

	.el-dropdown-menu__item.is-disabled .projectDetailing__bodyCardsOptions {
		color: #bbbbbb;
	}

	.el-dropdown-menu__item.is-disabled .projectDetailing__bodyCardsOptions .projectDetailing__bodyCardsOptions_Icon_0 {
		background-image: url('./../assets/cardIconPencilGrey.svg');
	}

	.el-dropdown-menu__item.is-disabled .projectDetailing__bodyCardsOptions .projectDetailing__bodyCardsOptions_Icon_1 {
		background-image: url('./../assets/renameIconGray.svg');
	}

	.el-dropdown-menu__item.is-disabled .projectDetailing__bodyCardsOptions .projectDetailing__bodyCardsOptions_Icon_2 {
		background-image: url('./../assets/duplicate.svg');
	}

	.el-dropdown-menu__item.is-disabled .projectDetailing__bodyCardsOptions .projectDetailing__bodyCardsOptions_Icon_4 {
		background-image: url('./../assets/blueClockGrey.svg');
	}

	.el-dropdown-menu__item.is-disabled .projectDetailing__bodyCardsOptions .projectDetailing__bodyCardsOptions_Icon_5 {
		background-image: url('./../assets/accessExecutionsIconGrey.svg');
	}

	.el-dropdown-menu__item.is-disabled .projectDetailing__bodyCardsOptions .projectDetailing__bodyCardsOptions_Icon_6 {
		background-image: url('./../assets/manageVariablesIconGrey.svg');
	}

	.el-dropdown-menu__item.is-disabled .projectDetailing__bodyCardsOptions .projectDetailing__bodyCardsOptions_Icon_8 {
		background-image: url('../assets/linkIconGray.svg');
	}

	.el-dropdown-menu__item.is-disabled .projectDetailing__bodyCardsOptions .projectDetailing__bodyCardsOptions_Icon_9 {
		background-image: url('../assets/shareIcon.svg');
	}

	.el-dropdown-menu__item.is-disabled .activeDeactiveButton__select .activeDeactiveButton__selectAux .activeDeactiveButton__labelActive {
		background: #696969;
		color: rgba(255, 255, 255, 0.48);
		border: 1px solid #000;
		pointer-events: none;
	}

	.el-dropdown-menu__item.is-disabled .activeDeactiveButton__select .activeDeactiveButton__selectAux .activeDeactiveButton__labelInactive {
		background: #696969;
		color: rgba(255, 255, 255, 0.48);
		border: 1px solid #000;
		pointer-events: none;
	}
}

</style>
