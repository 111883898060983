import { searchPermissions, verifyPermissionDescription, hasPermissionByDescription } from '@/components/helpers';
import { PERMISSION_IDS } from '@/constants';
import AuthService from '@/services/auth.service';
import Vue from 'vue';

import WebStorage from '@/common/WebStorage';

export const workspacePermission = Vue.extend({
	data() {
		return {
			PERMISSION_IDS,
			permission: '',
			manageWorkspace: '',
			dataWorkspace: '',
		};
	},

	async created() {
		//await this.validationAccessWorkspace();
	},

	computed: {
		manageWorkspacePermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageWorkspaceId, this.permission?.access);
		},

		createWorkspacePermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.createWorkspaceId, this.manageWorkspace?.childrens);
		},
		editWorkspacePermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.editWorkspaceId, this.manageWorkspace?.childrens);
		},
		deleteWorkspacePermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.deleteWorkspaceId, this.manageWorkspace?.childrens);
		},
	},

	methods: {
		async validationAccessWorkspace() {
			const result = await searchPermissions();

			if (result === false) {
				await AuthService.logout();

				return this.$store.commit('activeAlert', {
					message: this.$locale.baseText('login.noPermission'),
					status: 'error',
				});
			} else {
				this.permission = JSON.parse(WebStorage.getItem('userPermission'));
				this.manageWorkspace = verifyPermissionDescription(this.PERMISSION_IDS?.manageWorkspaceId, this.permission?.access);

				if (!this.manageWorkspace) {
					this.$router.push({
						name: 'users',
					});

					return this.$store.commit('activeAlert', {
						message: this.$locale.baseText('permission.noPermissionFuncionality'),
						status: 'error',
					});
				} else if (!this.permission && (this.$route.name == 'createProfile' || this.$route.name == 'editProfile')) {
					this.$router.push({
						name: 'workspace',
					});
				}
			}
		},

		canManageWorkspace() {
			return this.manageWorkspacePermission;
		},

		canAccessWorkspace(active) {
			if (active !== undefined) {
				return this.manageWorkspacePermission && active;
			}
			return this.manageWorkspacePermission;
		},

		canCreateWorkspace() {
			return this.createWorkspacePermission;
		},
		canEditeWorkspace() {
			return this.editWorkspacePermission;
		},
		canActiveInactiveWorkspace() {
			return this.editWorkspacePermission;
		},
		canDeleteWorkspace() {
			return this.deleteWorkspacePermission;
		},
	},
});
