
import BackgroundView from './BackgroundView.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import { genericHelpers } from '@/components/mixins/genericHelpers';
import { mapActions } from 'vuex';
import { restApi } from '@/components/mixins/restApi';
import mixins from 'vue-typed-mixins';
import { RETRY_EXECUTION_MODAL_KEY, PERMISSION_IDS } from '@/constants';
import WebStorage from '@/common/WebStorage';
import moment from 'moment';
import { executionLogPermission } from '@/components/mixins/permissions/executionLog';
import { searchPermissions, removeItemFromArrayByValue } from '@/components/helpers';
import { previousRoute } from '../router/index.ts';

import SelectFilter from '@/components/SelectFilter.vue';
import FilterTable from '@/components/FilterTable.vue';
import DatePicker from '@/components/DatePicker2.vue';
import InteractionMenu from '@/components/InteractionMenu.vue';
import Table from '@/components/Table.vue';
import FilterBar from '@/components/FilterBar.vue';
import FilterIcon from '@/components/FilterIcon.vue';

export default mixins(
	restApi,
	executionLogPermission,
)
.extend({
	components: {
		BackgroundView,
		ModalButtonSuccess,
		InteractionMenu,
		genericHelpers,
		Table,
		SelectFilter,
		FilterTable,
		DatePicker,
		FilterBar,
		FilterIcon,
	},
	name: 'ExecutionDetail',
	data() {
		return {
			isLoadingPage: false,
			isLoadingTable: false,
			automaticPicture: '',
			executionDetail__button: 'executionDetail.executionDetail__button',
			executionDetail__firstTitle: 'executionDetail.executionDetail__firstTitle',
			executionDetail__executionTitle: 'executionDetail.executionDetail__executionTitle',
			executionDetail__bodySectionBodyUnitTitle_1:
				'executionDetail.executionDetail__bodySectionBodyUnitTitle_1',
			executionDetail__bodySectionBodyUnitTitle_2:
				'executionDetail.executionDetail__bodySectionBodyUnitTitle_2',
			executionDetail__bodySectionBodyUnitTitle_3:
				'executionDetail.executionDetail__bodySectionBodyUnitTitle_3',
			executionDetail__bodySectionBodyUnitTitle_4:
				'executionDetail.executionDetail__bodySectionBodyUnitTitle_4',
			executionDetail__bodySectionBodyUnitTitle_5:
				'executionDetail.executionDetail__bodySectionBodyUnitTitle_5',
			executionDetail__bodySectionBodyUnitTitle_6:
				'executionDetail.executionDetail__bodySectionBodyUnitTitle_6',
			executionDetail__bodySectionBodyUnitTitle_7:
				'executionDetail.executionDetail__bodySectionBodyUnitTitle_7',
			executionDetail__bodySectionBodyUnitTitle_8:
				'executionDetail.executionDetail__bodySectionBodyUnitTitle_8',
			interactionMenu__buttonToCleanFilter: 'executionLog.interactionMenu__buttonToCleanFilter',

			executionDetail__bodySectionTitleLast:
				'executionDetail.executionDetail__bodySectionTitleLast',
			executionDetail__bodySectionBodyUnitInfoLast:
				'executionDetail.executionDetail__bodySectionBodyUnitInfoLast',

			executionDetail__bodySectionBodyUnitInfoLastWarningText_1: 'A execução resultou em',
			executionDetail__bodySectionBodyUnitInfoLastWarningText_2: ' e retornou a seguinte mensagem: ',
			executionDetail__bodySectionBodyUnitInfoLastWarningText_3: 'Unable to convert user signature.',

			interactionMenu__titleHeader: 'executionLog.interactionMenu__titleHeader',
			interactionMenu__headerDropdownPlaceHolder:
				'executionLog.interactionMenu__headerDropdownPlaceHolder',
			interactionMenu__buttonToRegisterVariable:
				'executionLog.interactionMenu__buttonToRegisterVariable',

			tableTitle__1: 'executionLog.tableTitle__dateAndTime',
			tableTitle__2: 'executionLog.tableTitle__durations',
			tableTitle__3: 'executionLog.tableTitle__identifiers',
			tableTitle__4: 'executionLog.tableTitle__results',
			tableTitle__5: 'executionLog.tableTitle__flowsAndTheirProjects',
			tableTitle__6: 'executionLog.tableTitle__actions',
			tableTitle__7: 'executionLog.tableTitle__attached',

			executionLog__tableActionsButtonLeftDropdown: 'executionLog.executionLog__tableActionsButtonLeftDropdown',
			executionLog__tableActionsButtonRightDropdown: 'executionLog.executionLog__tableActionsButtonRightDropdown',
			executionLog__tableActionsRetryExecution: 'executionLog.executionLog__tableActionsRetryExecution',

			infoPage: '',
			accountName: '',
			maxheight: '60vh',
			dataAttachedExecution: [],
			dataTableTemp: [],
			scrollInfinite: true,
			page: 1,
			pageSize: 10,
			filtering: false,
			applyedFilters: [],
			dataTableFilteredById: [],
			dataTableFilteredByName: [],
			lastFilterDate: '',
			lastFilterAttached: '',
			componentKey: 0,

			queryDate: {
				inicio: this.$route.query?.inicio || '',
				fim: this.$route.query?.fim || '',
			},
			queryResults: this.$route.query.resultado || [],
			filtersActive: {},
			previousRoute: previousRoute,
			clearfiltering: false,
		};
	},
	computed: {
		executionId() {
			return this.$route.params.id;
		},
		resultText() {
			if (typeof this.queryResults === 'string') {
				return this.queryResults;
			}
			const length = this.queryResults.length;
			return length === 1 ? this.queryResults[0] : length === 0 ? 'Resultados' : `${this.queryResults[0]} +${length - 1}`;
		},

		intervalDateText() {
			if (this.queryDate.inicio == '' && this.queryDate.fim == '') {
					return 'Datas e Horários';
			}

			const inicioFormatado = this.queryDate.inicio ? this.formatDate(this.queryDate.inicio) : 'Data Inicial';
			const fimFormatado = this.queryDate.fim ? this.formatDate(this.queryDate.fim) : 'Data Atual';
			return `De: ${inicioFormatado}\nAté: ${fimFormatado}`;
		},

		resultQuery() {
			if (typeof this.$route.query?.resultado === 'string') {
				return [this.$route.query?.resultado];
			}
			return this.$route.query?.resultado;
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	async created() {
		this.initAttachedExecution();

		this.isLoadingPage = true;
		await this.loadPage();
		this.isLoadingPage = false;
	},
	methods: {

		...mapActions('aliareAccount', ['getAccountDataV2',]),

		async initAttachedExecution() {
			const applyPage = WebStorage.getItem('applyPage');
			if (this.previousRoute.name == 'ExecutionById' && applyPage == 'executionDetail') {
				this.$router.replace({query: { ...this.$store.getters?.filters } }).catch(() => {});

				this.loadPlaceholders(this.$route.query);
			};

			if(Object.keys(this.$route.query).length) {
				this.filterTable();
			} else {
				await this.getAttachedExecution();
			}
		},
		loadPlaceholders(query) {
			this.queryDate.inicio = query?.inicio ?? '';
			this.queryDate.fim = query?.fim ?? '';
			this.queryResults = query?.resultado?.map(item => item.replace("Nao_Armazenado", "Executando / Não Armazenado")) ?? [];
		},

		async filterBySearch(search) {
			this.filtering = search.length <= 2 ? true : false;
			this.addOrRemoveFilter(this.filtersActive, 'filterSearch', search.length <= 2 ? '' : search);
		},
		async filterByResult(query) {
			const replaceQuery = query.map(item => item.replace("Executando / Não Armazenado", "Nao_Armazenado"));

			this.queryResults = query;
			this.addOrRemoveFilter(this.filtersActive, 'filterResults', replaceQuery);
			this.filtering = !this.queryResults.length;
		},
		async filterByDate(start, end) {
			if (start === 'Invalid date') start = '';
			if (end === 'Invalid date') end = '';

			this.queryDate = (start === '' && end === '') ? '' : { inicio: start, fim: end };
			this.filtering = (this.queryDate === '');

			this.addOrRemoveFilter(this.filtersActive, 'filterDate', this.queryDate);
		},

		async filterTable(filters) {
			try {
				this.isLoadingTable = true;

				const routeQuery = this.$route.query;
				const payload = {
					inicio: filters?.filterDate?.inicio || routeQuery?.inicio,
					fim: filters?.filterDate?.fim || routeQuery?.fim,
					size: 10,
					searchNomeOuId: filters?.filterSearch || routeQuery?.searchNomeOuId,
					resultado: filters?.filterResults || routeQuery?.resultado,
				};

				this.$router.replace({query: { ...payload }}).catch(() => {});

				const dataFilter = await this.restApi().attachedExecutions(this.workspace.id, this.executionId, payload);
				await this.applyFilter(dataFilter, payload);
			} catch (e) {
				this.$store.commit('activeAlert', {
					message: e.notifications[0],
					status: 'error',
				});
				this.clearfiltering = true;
			} finally {
				this.isLoadingTable = false;
			}
		},

		async applyFilter(dataFiltered, query) {
			this.page = 1;
			this.filtering = true;
			this.clearfiltering = true;
			this.scrollInfinite = true;
			this.dataAttachedExecution = dataFiltered;

			this.$store.commit('setFilters', query);
			WebStorage.setItem('applyPage', 'executionDetail');
		},

		async clearFilter() {
			this.page = 1;
			this.filtering = false;
			this.clearfiltering = false;
			this.filtersActive = {};
			this.queryResults = [];
			this.scrollInfinite = true;
			this.dataAttachedExecution = [];
			this.queryDate = {
				inicio: '',
				fim: '',
			};

			await this.$router.replace({'query': null}).catch(() => {});

			this.$store.commit('setFilters', this.filtersActive);
			WebStorage.removeItem('applyPage');
			this.getAttachedExecution();
    },
		addOrRemoveFilter(object, property, value) {
			if ((Array.isArray(value) && value.length === 0) || value === '') {
				this.$delete(object, property);
			} else {
				this.$set(object, property, value);
			}
		},


		openModalRetryExecution() {
			this.$store.dispatch("ui/openModal", RETRY_EXECUTION_MODAL_KEY);
			this.$store.commit('setActiveExecutionId', this.$route.params.id);
		},
		displayExecution(executionData: any, e: PointerEvent) {
			if (e.metaKey || e.ctrlKey) {
				const route = this.$router.resolve({ name: 'ExecutionById', params: { id: executionData.id } });
				window.open(route.href, '_blank');

				return;
			}

			this.$router.push({
				name: 'ExecutionById',
				params: { id: executionData.id },
			});
		},
		async loadPage() {
			try {
				const resultDetail = await this.restApi().getExecutionById(this.workspace.id, this.$route.params.id);
				this.infoPage = resultDetail;

				const name = await this.getAccountDataV2(resultDetail.tenant.id);
				this.accountName = name.description;
			} catch(error) {
				console.log('error', error);
			}
		},
		formatDate(date) {
			let dateUtc = moment.utc(date);
			let localDate = moment(dateUtc).local().format('DD/MM/YYYY [às] HH[h]:mm[min]');
			return localDate;
		},
		formatDuration(timestamp) {
			let hours = timestamp.substring(0, 2) + 'h:';
			let minutes = timestamp.substring(3, 5) + 'min:';
			let seconds = timestamp.substring(6, 8) + 's';

			return hours + minutes + seconds;
		},


		async loadExecutionsScroll() {
			if (this.dataAttachedExecution.length % this.pageSize === 0) {
				try {
					this.page++;

					const payload = {
						...this.$route.query,
						size: this.pageSize,
						page: this.page,
					};

					const response = await this.restApi().attachedExecutions(this.workspace.id, this.executionId, payload);
					this.dataAttachedExecution.push(...response);
					this.dataTableTemp = this.dataAttachedExecution;

					if (response.length === 0) {
						this.scrollInfinite = false;
					}
				} catch (error) {
					console.error(error);
					this.scrollInfinite = false;
				}
			} else {
				this.scrollInfinite = false;
			}
		},

		accessDetailExecution (id) {
			this.$router.push({
				name: 'executionDetail',
				params: { id },
			});
		},

		async getAttachedExecution() {
			this.isLoadingTable = true;
			const payload = {
				...this.$route.query,
				size: this.pageSize,
				page: this.page,
			};

			await this.restApi().attachedExecutions(this.workspace.id, this.executionId, payload).then((response) => {
				this.dataAttachedExecution.push(...response);
				this.dataTableTemp = this.dataAttachedExecution;

				if (response.length !== 10) {
					this.scrollInfinite = false;
				}
			}).catch((error) => {
				console.error(error);
			});

			this.isLoadingTable = false;
		},
	},
	beforeDestroy() {
		this.$store.commit('setActiveExecutionId', null);
    this.$store.commit('setOpenRouteName', 'empty');
  },
});
