import { hasPermissionByDescription, verifyPermissionDescription, searchPermissions } from '@/components/helpers';
import { PERMISSION_IDS } from '@/constants';
import AuthService from '@/services/auth.service';
import Vue from 'vue';

import WebStorage from '@/common/WebStorage';

export const equivalencePermission = Vue.extend({
	data () {
		return {
			PERMISSION_IDS,
			dataEquivalence: '',
			dataIntegration: '',
			validate: false,
		};
	},

	async created() {
		if (!this.validate) {
			await this.validationAccessEquivalences();
		}
	},

	computed: {
		manageEquivalencesPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageEquivalencesId, this.dataIntegration?.childrens);
		},

		registerEquivalencePermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.registerEquivalenceId, this.dataEquivalence?.childrens);
		},
		deleteEquivalencesPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.deleteEquivalenceId, this.dataEquivalence?.childrens);
		},
		shareEquivalencePermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.shareEquivalenceId, this.dataEquivalence?.childrens);
		},
		editEquivalencesPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.editEquivalenceId, this.dataEquivalence?.childrens);
		},

		registerDataSourcePermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.registerDataSourceId, this.dataEquivalence?.childrens);
		},
		editDataSourcePermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.editDataSourceId, this.dataEquivalence?.childrens);
		},
		deleteDataSourcePermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.deleteDataSourceId, this.dataEquivalence?.childrens);
		},
		activeInactiveDataSourcePermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.activeInactiveDataSourceId, this.dataEquivalence?.childrens);
		},
		showEquivalencesPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.manageEquivalencesId, this.dataIntegration?.childrens);
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	methods: {
		async validationAccessEquivalences() {
			const result = await searchPermissions();

			if(result === false) {
				await AuthService.logout();

				return this.$store.commit('activeAlert', {
					message: this.$locale.baseText('login.noPermission'),
					status: 'error',
				});
			} else {
				const permission = JSON.parse(WebStorage.getItem('userPermission'));
				this.dataIntegration = verifyPermissionDescription(this.PERMISSION_IDS?.manageIntegrationId, permission?.access);
				this.dataEquivalence = verifyPermissionDescription(this.PERMISSION_IDS?.manageEquivalencesId, this.dataIntegration?.childrens);

				if (!this.dataIntegration && !this.dataEquivalence ) {
					await AuthService.logout();

					return this.$store.commit('activeAlert', {
						message: this.$locale.baseText('login.noPermission'),
						status: 'error',
					});
				} else {
					this.validate = true;
					return true;
				}
			}
		},

		canAccessEquivalenceDetail() {
			return this.manageEquivalencesPermission && this.workspace.ativo;
		},

		canCreateEquivalences() {
			return this.registerEquivalencePermission && this.workspace.ativo;
		},
		canImportEquivalence() {
			return this.registerEquivalencePermission && this.workspace.ativo;
		},

		canDeleteEquivalence(equivalence) {
			return this.deleteEquivalencesPermission && equivalence.permissao == 'Editar' && this.workspace.ativo ? true : false;
		},
		canShareEquivalence(equivalence) {
			return this.shareEquivalencePermission && equivalence.permissao == 'Editar' && this.workspace.ativo ? true : false;
		},
		canViewShareEquivalence() {
			return this.shareEquivalencePermission && this.workspace.ativo;
		},
		canEditEquivalence(equivalence) {
			return this.editEquivalencesPermission && equivalence.permissao == 'Editar' && this.workspace.ativo ? true : false;
		},

		canCreateDataSource() {
			return this.registerDataSourcePermission && this.workspace.ativo;
		},
		canEditDataSource() {
			return this.editDataSourcePermission && this.workspace.ativo;
		},
		canDeleteDataSource() {
			return this.deleteDataSourcePermission && this.workspace.ativo;
		},
		canActiveInactiveDataSource() {
			return this.activeInactiveDataSourcePermission && this.workspace.ativo;
		},
		canAccessDataSourceDetail() {
			return this.manageEquivalencesPermission && this.workspace.ativo;
		},
	},
});
