import { hasPermissionByDescription, verifyPermissionDescription, searchPermissions } from '@/components/helpers';
import { PERMISSION_IDS } from '@/constants';
import AuthService from '@/services/auth.service';
import Vue from 'vue';


import WebStorage from '@/common/WebStorage';

export const workflowPermission = Vue.extend({
	data () {
		return {
			PERMISSION_IDS,
			dataWorkflows: '',
			dataIntegration: '',
		};
	},

	computed: {
		openFlowPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.openFlowId, this.dataWorkflows?.childrens);
		},
		createFlowPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.createFlowId, this.dataWorkflows?.childrens);
		},
		duplicateFlowPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.duplicateFlowId, this.dataWorkflows?.childrens);
		},
		deleteFlowPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.deleteFlowId, this.dataWorkflows?.childrens);
		},
		editFlowPermission() {
			return hasPermissionByDescription(this.PERMISSION_IDS?.editFlowId, this.dataWorkflows?.childrens);
		},
		workspace() {
			return this.$store.getters.workspace;
		},
	},
	async created() {
		await this.validationAccessWorkflow();
	},

	methods: {
		async validationAccessWorkflow() {
			const result = await searchPermissions();

			if(result === false) {
				await AuthService.logout();

				return this.$store.commit('activeAlert', {
					message: this.$locale.baseText('login.noPermission'),
					status: 'error',
				});
			} else {
				const permission = JSON.parse(WebStorage.getItem('userPermission'));
				this.dataIntegration = verifyPermissionDescription(this.PERMISSION_IDS?.manageIntegrationId, permission?.access);
				this.dataWorkflows = verifyPermissionDescription(this.PERMISSION_IDS?.manageProjectId, this.dataIntegration?.childrens);

				if (!this.dataIntegration && !this.dataWorkflows) {
					await AuthService.logout();

					return this.$store.commit('activeAlert', {
						message: this.$locale.baseText('login.noPermission'),
						status: 'error',
					});
				} else if (!this.dataWorkflows) {
					this.$router.push({ name: 'project' });
				}
			}
		},

		canEditFlow(active) {
			if (!active) {
				return this.editFlowPermission && this.workspace.ativo;
			}
			return this.editFlowPermission && active && this.workspace.ativo ? true : false;
		},
		canEditFlowInProjectDetail(workflow, project) {
			if (!project) {
				return this.editFlowPermission && workflow.ativo && workflow.permissao == 'Editar' && this.workspace.ativo ? true : false;
			}
			return this.editFlowPermission && project.ativo && workflow.ativo && project.permissao == 'Editar' && this.workspace.ativo ? true : false;
		},
		canOpenFlow(projectStatusActive) {
			if (!projectStatusActive) {
				return this.openFlowPermission && this.workspace.ativo;
			}
			return this.openFlowPermission && projectStatusActive && this.workspace.ativo ? true : false;
		},
		canCreateFlows(project, projectStatusActive) {
			return this.createFlowPermission && projectStatusActive && project.permissao == 'Editar' && this.workspace.ativo ? true : false;
		},
		canRenameFlow(workflow, project) {
			return this.editFlowPermission && project.ativo && workflow.ativo && project.permissao == 'Editar' && this.workspace.ativo ? true : false;
		},
		canActiveInactiveFlow(project) {
			return this.editFlowPermission && project.ativo && project.permissao == 'Editar' && this.workspace.ativo ? true : false;
		},
		canChangeAutomaticManualFlow(workflow, project) {
			if (!workflow) {
				return this.editFlowPermission && project.ativo && this.workspace.ativo ? true : false;
			}
			return this.editFlowPermission && project.ativo && workflow.ativo && project.permissao == 'Editar' && this.workspace.ativo ? true : false;
		},

		canAccessVersion(workflow, statusActive) {
			if (!workflow) {
				return (this.editFlowPermission || this.openFlowPermission) && statusActive && this.workspace.ativo ? true : false;
			}
			return (this.editFlowPermission || this.openFlowPermission) && statusActive && workflow.ativo && this.workspace.ativo ? true : false;
		},
		canAccessExecutionFlow(workflow, statusActive) {
			if (!workflow) {
				return (this.editFlowPermission || this.openFlowPermission) && statusActive && this.workspace.ativo ? true : false;
			}
			return (this.editFlowPermission || this.openFlowPermission) && statusActive && workflow.ativo && this.workspace.ativo ? true : false;
		},
		canAccessAttachmentFlows(workflow, statusActive) {
			if (!workflow) {
				return (this.editFlowPermission || this.openFlowPermission) && statusActive && this.workspace.ativo ? true : false;
			}
			return (this.editFlowPermission || this.openFlowPermission) && statusActive && workflow.ativo && this.workspace.ativo ? true : false;
		},
		canManageVariablesFlow(workflow, statusActive) {
			if (!workflow) {
				return (this.editFlowPermission || this.openFlowPermission) && statusActive && this.workspace.ativo ? true : false;
			}
			return (this.editFlowPermission || this.openFlowPermission) && statusActive && workflow.ativo && this.workspace.ativo ? true : false;
		},
		canDuplicateFlow(workflow, project) {
			return this.duplicateFlowPermission && project.ativo && workflow.ativo && this.workspace.ativo ? true : false;
		},
		canDeleteFlow(project) {
			return this.deleteFlowPermission && project.permissao == 'Editar' && this.workspace.ativo;
		},

		canReprocessExecution(resultExecution) {
			return (this.editFlowPermission || this.openFlowPermission) && resultExecution === 'Falha' && this.workspace.ativo ? true : false;
		},
		canViewDetailsExecution() {
			return (this.editFlowPermission || this.openFlowPermission) && this.workspace.ativo;
		},
		canOpenExecution() {
			return (this.editFlowPermission || this.openFlowPermission) && this.workspace.ativo;
		},
	},
});
